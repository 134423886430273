import React from "react";
import requireAuth from "../auth/requireAuth";
import ContaTableGrid from "./ContaTableGrid";

class ContaList extends React.Component {
  render() {
    return (
      <div className="ui centered grid">
        <div className="ui fifteen wide column">
          <ContaTableGrid />
        </div>
      </div>
    );
  }
}

export default requireAuth(ContaList);
