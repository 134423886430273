import React from "react";
import requireAuth from "../auth/requireAuth";
import { connect } from "react-redux";
import { fetchUsers } from "../../actions";
// import BarChart from "./BarChart";
// import DoughnutChart from "./DoughnutChart";
import DashboardListReport from "./DashboardListReports";

class DashboardShow extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    this.props.fetchUsers();
  }

  renderUserInfo() {
    const { userData } = this.props;
    // console.log(userData);

    if (!userData) {
      return <div className="ui active centered inline loader" />;
    }

    return userData.map((user) => {
      if (user.id === this.props.currentUserId) {
        return (
          <div className="ui item" key={user.id}>
            <br />
            <h3 className="ui header">Bem Vindo! {user.name}</h3>
            <br />
            <br />
            <DashboardListReport />
          </div>
        );
      }

      return null;
    });
  }

  render() {
    return (
      <div className="ui centered grid">
        <div className="ui fifteen wide column">
          <div className="ui divided list">
            <h2 className="ui header">DashBoard</h2>
            {this.renderUserInfo()}
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    userData: Object.values(state.users),
    currentUserId: state.auth.userId,
  };
};

export default requireAuth(
  connect(mapStateToProps, { fetchUsers })(DashboardShow)
);
