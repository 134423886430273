import React, { Component } from "react";
import { connect } from "react-redux";

import history from "../../history";

export default function foo(ComposedComponent) {
  class Authentication extends Component {
    componentWillMount() {
      if (!this.props.isAuthenticated) {
        history.push("/");
      }
    }
    componentWillUpdate(nextProps) {
      // console.log(this.props);
      if (!nextProps.isAuthenticated) {
        history.push("/");
      }
    }

    render() {
      return <ComposedComponent {...this.props} />;
    }
  }

  function mapStateToProps(state) {
    const { isAuthenticated } = state.auth;
    return { isAuthenticated };
  }

  return connect(mapStateToProps)(Authentication);
}
