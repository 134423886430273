import { USER_LOGIN_SUCCESS, USER_LOGOUT } from "../actions/types";

const INITIAL_STATE = {
  token: null,
  userId: null,
  profile: null,
  userData: null,
  isAuthenticated: false,
  isAdmin: false,
};

export default function foo(state = INITIAL_STATE, action) {
  switch (action.type) {
    case USER_LOGIN_SUCCESS:
      // console.log("From reducer:" + action.userId);
      return {
        ...state,
        token: action.token,
        userId: action.userId,
        profile: action.profile,
        isAuthenticated: true,
        isAdmin: action.isAdmin,
      };
    case USER_LOGOUT:
      return {
        ...state,
        token: null,
        userId: null,
        profile: null,
        isAuthenticated: false,
        isAdmin: false,
      };

    default:
      return state;
  }
}
