import React from "react";
import requireAuth from "../auth/requireAuth";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { fetchCompanies, fetchCompany } from "../../actions";

class CompanyShow extends React.Component {
  componentDidMount() {
    this.props.fetchCompanies();
  }

  renderCompanyInfo() {
    const { companyData } = this.props;

    if (!companyData) {
      return <div className="ui active centered inline loader" />;
    }

    return (
      <>
        <div className="ui header">
          <h3>
            <i aria-hidden="true" className="settings icon" />
            <span> CNPJ:&nbsp; {companyData.document}</span>
          </h3>
        </div>
        <div className="ui vertically grid">
          <div className="two column row">
            <div className="column">
              <div className="ui meta">
                <h5>Nome Fantasia:</h5>
              </div>
              <br />
              <div className="ui content">
                <h4>{companyData.fantasyName}</h4>
              </div>
              <div className="ui divider" />
            </div>
            <div className="column">
              <div className="ui meta">
                <h5>Sigla:</h5>
              </div>
              <br />
              <div className="ui content">
                <h4>{companyData.initials}</h4>
              </div>
              <div className="ui divider" />
            </div>
          </div>
          <div className="three column row">
            <div className="column">
              <div className="ui meta">
                <h5>Grupo Empresarial:</h5>
              </div>
              <br />
              <div className="ui content">
                <h4>{companyData.conta.fantasyName}</h4>
              </div>
              <div className="ui divider" />
            </div>
            <div className="column">
              <div className="ui meta">
                <h5>Razão Social</h5>
              </div>
              <br />
              <div className="ui content">
                <h4>{companyData.socialReason}</h4>
              </div>
              <div className="ui divider" />
            </div>
            <div className="column">
              <div className="ui meta">
                <h5>Segmento</h5>
              </div>
              <br />
              <div className="ui content">
                <h4>{companyData.segment.name}</h4>
              </div>
              <div className="ui divider" />
            </div>
          </div>
          <div className="two column row">
            <div className="column">
              <div className="ui meta">
                <h5>Inscrição Estadual</h5>
              </div>
              <br />
              <div className="ui content">
                <h4>{companyData.stateRegistration}</h4>
              </div>
              <div className="ui divider" />
            </div>
            <div className="column">
              <div className="ui meta">
                <h5>Inscrição Municipal</h5>
              </div>
              <br />
              <div className="ui content">
                <h4>{companyData.municipalRegistration}</h4>
              </div>
              <div className="ui divider" />
            </div>
          </div>
          <div className={"row"}>
            <div
              className={
                "ui twelve wide right aligned column bottom aligned content"
              }
            >
              <h5>Endereço</h5>
            </div>
            <div
              className={
                "ui four wide right aligned column bottom aligned content"
              }
            >
              <Link
                className={"ui mini icon right labeled button"}
                to={"/company/list"}
              >
                <i aria-hidden="true" className="reply icon" /> Voltar
              </Link>
            </div>
          </div>
          <div className="ui divider" />
          <div className="two column row">
            <div className="column">
              <div className="ui meta">
                <h5>CEP:</h5>
              </div>
              <br />
              <div className="ui content">
                <h4>{companyData.address.zip}</h4>
              </div>
              <div className="ui divider" />
            </div>
            <div className="column">
              <div className="ui meta">
                <h5>Logradouro:</h5>
              </div>
              <br />
              <div className="ui content">
                <h4>{companyData.address.street}</h4>
              </div>
              <div className="ui divider" />
            </div>
          </div>
          <div className="ui three column row">
            <div className="column">
              <div className="ui meta">
                <h5>Numero:</h5>
              </div>
              <br />
              <div className="ui content">
                <h4>{companyData.address.number}</h4>
              </div>
              <div className="ui divider" />
            </div>
            <div className="column">
              <div className="ui meta">
                <h5>Complemento:</h5>
              </div>
              <br />
              <div className="ui content">
                <h4>{companyData.address.compliment}</h4>
              </div>
              <div className="ui divider" />
            </div>
            <div className="column">
              <div className="ui meta">
                <h5>Bairro:</h5>
              </div>
              <br />
              <div className="ui content">
                <h4>{companyData.address.neighbourhood}</h4>
              </div>
              <div className="ui divider" />
            </div>
          </div>
          <div className="two column row">
            <div className="column">
              <div className="ui meta">
                <h5>Estado:</h5>
              </div>
              <br />
              <div className="ui content">
                <h4>{companyData.address.state}</h4>
              </div>
              <div className="ui divider" />
            </div>
            <div className="column">
              <div className="ui meta">
                <h5>Cidade:</h5>
              </div>
              <br />
              <div className="ui content">
                <h4>{companyData.address.city}</h4>
              </div>
              <div className="ui divider" />
            </div>
          </div>
        </div>
      </>
    );
  }

  render() {
    return (
      <div className={"ui container"}>
        <div className={"ui grid"}>
          <div className={"ui row"}>
            <div
              className={
                "ui eight wide left aligned column bottom aligned content"
              }
            >
              <h2>Visualização da Empresa</h2>
            </div>
            <div
              className={
                "ui four wide right aligned column bottom aligned content"
              }
            >
              <h5>Identificacão</h5>
            </div>
            <div
              className={
                "ui four wide right aligned column bottom aligned content"
              }
            >
              <Link
                className={"ui mini icon right labeled button"}
                to={"/company/list"}
              >
                <i aria-hidden="true" className="reply icon" /> Voltar
              </Link>
            </div>
          </div>
        </div>
        <div className="ui divider" />
        {this.renderCompanyInfo()}
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    companyData: state.companies[ownProps.match.params.id],
  };
};

export default requireAuth(
  connect(mapStateToProps, { fetchCompanies, fetchCompany })(CompanyShow)
);
