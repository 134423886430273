import _ from "lodash";
import React from "react";
import requireAuth from "../auth/requireAuth";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import {
  editReport,
  fetchReport,
  fetchCompanies,
  fetchUsers,
  fetchServiceTypes,
  fetchEquipmentTypes,
} from "../../actions";
import ReportForm from "./ReportForm";

class ReportEdit extends React.Component {
  componentDidMount() {
    this.props.fetchReport(this.props.match.params.id);
  }

  onSubmit = (formValues) => {
    //console.log(formValues.file);
    if (formValues.file == "") {
      formValues.file = this.props.report.file;
    }
    //console.log(formValues);
    this.props.editReport(this.props.match.params.id, formValues);
  };

  render() {
    if (!this.props.report) {
      return <div className="ui active centered inline loader" />;
    }

    return (
      <div className={"ui container"}>
        <div className={"ui grid"}>
          <div className={"ui row"}>
            <div
              className={
                "ui eight wide left aligned column bottom aligned content"
              }
            >
              <h2>Edição de Laudo</h2>
            </div>
            <div
              className={
                "ui four wide right aligned column bottom aligned content"
              }
            >
              <h5>Identificação</h5>
            </div>
            <div
              className={
                "ui four wide right aligned column bottom aligned content"
              }
            >
              <Link
                className={"ui mini icon right labeled button"}
                to={"/report/list"}
              >
                <i aria-hidden="true" className="reply icon" /> Voltar
              </Link>
            </div>
          </div>
        </div>
        <div className={"ui divider"} />
        <ReportForm
          initialValues={_.pick(
            this.props.report,
            "id",
            "company.fantasyName",
            "company[@id]",
            "company.document",
            "company.id",
            "orderService.title",
            "orderService[@id]",
            "orderService.id",
            "title",
            "startDate",
            "endDate",
            "services",
            "equipments",
            "observation",
            "observationDel",
            "file",
            "oldReport",
            "isArchived",
            "isNotSatisfactory",
            "isDeleted",
            "isReleased",
            "userDidAction",
            "userDid[@id]",
            "userDid.id",
          )}
          disable={true}
          onSubmit={this.onSubmit}
        />
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    report: state.reports[ownProps.match.params.id],
  };
};

export default requireAuth(
  connect(mapStateToProps, {
    editReport,
    fetchReport,
    fetchCompanies,
    fetchUsers,
    fetchServiceTypes,
    fetchEquipmentTypes,
  })(ReportEdit)
);
