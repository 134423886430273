import React from "react";
import requireAuth from "../auth/requireAuth";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { Field, reduxForm } from "redux-form";

import { cnpjValidate } from "../../util/cnpj-cpf-validate/cnpjValidate";

class ContaForm extends React.Component {
  renderError({ touched, error }) {
    if (touched && error) {
      return (
        <div className={"ui tiny error message"}>
          <div className={"header"}>{error}</div>
        </div>
      );
    }
  }

  renderInputCNPJ = ({ input, label, meta, disabled }) => {
    const className = `field ${meta.error && meta.touched ? "error" : ""}`;

    return (
      <div className={className}>
        <label>{label}</label>
        <input {...input} autoComplete={"off"} disabled={disabled} />

        {this.renderError(meta)}
      </div>
    );
  };

  renderInput = ({ input, label, meta }) => {
    const className = `field ${meta.error && meta.touched ? "error" : ""}`;
    return (
      <div className={className}>
        <label>{label}</label>
        <input {...input} autoComplete={"off"} />
        {this.renderError(meta)}
      </div>
    );
  };

  onSubmit = (formValues) => {
    this.props.onSubmit(formValues);
  };

  render() {
    // console.log(this.props);
    const { submitting } = this.props;
    return (
      <div>
        <form
          name={"conta"}
          className={"ui tiny form error"}
          onSubmit={this.props.handleSubmit(this.onSubmit)}
        >
          <div className={"ui equal width fields"}>
            <Field
              type={"text"}
              name={"fantasyName"}
              component={this.renderInput}
              label={"Grupo Empresarial"}
            />
          </div>
          <div className={"ui grid"}>
            <div className={"row"}>
              <div
                className={
                  "ui four wide right aligned column bottom aligned content"
                }
              />
            </div>
          </div>
          <div className={"ui divider"} />
          <div className={"ui container right aligned"}>
            <div className={"ui buttons"}>
              <Link className={"ui red button"} to={"/conta/list"}>
                Cancelar
              </Link>
              <div className={"or"} data-text={"ou"} />
              <button
                type="submit"
                className={"ui primary button"}
                disabled={submitting}
              >
                Salvar
              </button>
            </div>
          </div>
          <br />
          <br />
        </form>
      </div>
    );
  }
}

// const cnpjMask = createTextMask({
//   pattern: "99.999.999/9999-99",
//   stripMask: false,
// });

const validate = (formValues, initialValues) => {
  const errors = {};
  // console.log("LISTA DE CNPJ", initialValues.chekDocumentT);

  if (!formValues.document) {
    // console.log("DENTRO", initialValues.listDocumentValue);
    errors.document = "Campo Obrigatório informe o CNPJ";
  } else if (!cnpjValidate(formValues.document)) {
    errors.document = "CNPJ INVÁLIDO verifique a digitação";
  } else {
    if (typeof initialValues.initialValues === "undefined") {
      if (initialValues.listDocumentValue.indexOf(formValues.document) !== -1) {
        errors.document = "CNPJ JÁ Existe";
      }
    }
  }

  if (!formValues.socialReason) {
    errors.socialReason = "Campo Obrigatório informe a Razão Social";
  }

  if (!formValues.fantasyName) {
    errors.fantasyName = "Campo Obrigatório informe a Nome Fantasia";
  }

  return errors;
};

const formWrapped = reduxForm({
  form: "contaForm",
  enableReinitialize: true,
  validate,
})(ContaForm);

const mapStateToProps = (state) => {
  return {
    //  conta: Object.values(state.conta),
  };
};

export default requireAuth(
  connect(mapStateToProps, {
    //fetchConta,
  })(formWrapped)
);
