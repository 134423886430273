import React from "react";
import Dropzone from "react-dropzone";
import requireAuth from "../../components/auth/requireAuth";
import { connect } from "react-redux";

import { DropContainer, UploadMessage } from "./styles";

class DropboxUpload extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      uploadedFiles: [],
      files: [],
    };
  }

  renderDragMessage = (isDragActive, isDragReject) => {
    if (!isDragActive) {
      return <UploadMessage>Arraste o arquivo no formato PDF...</UploadMessage>;
    }
    if (isDragReject) {
      return (
        <UploadMessage type="error">
          Arquivo não suportado ou mais de um.
        </UploadMessage>
      );
    }
    return (
      <UploadMessage type="success">Pode soltar esse é válido.</UploadMessage>
    );
  };

  render() {
    const { onUpload } = this.props;

    const maxSize = 52428800;
    return (
      <Dropzone
        accept="application/pdf"
        minSize={0}
        maxSize={maxSize}
        maxFiles={1}
        onDropAccepted={onUpload}
      >
        {({ getRootProps, getInputProps, isDragActive, isDragReject }) => (
          <DropContainer
            {...getRootProps()}
            isDragActive={isDragActive}
            isDragReject={isDragReject}
          >
            <input {...getInputProps()} accept="application/pdf" />
            {this.renderDragMessage(isDragActive, isDragReject)}
          </DropContainer>
        )}
      </Dropzone>
    );
  }
}

export default requireAuth(connect(null, {})(DropboxUpload));
