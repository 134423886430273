import React from "react";
import requireAuth from "../auth/requireAuth";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { createCompany, fetchCompanies } from "../../actions";
import CompanyForm from "./CompanyForm";

class CompanyCreate extends React.Component {
  onSubmit = (formValues) => {
    this.props.createCompany(formValues);
  };

  render() {
    return (
      <div className={"ui container"}>
        <div className={"ui grid"}>
          <div className={"ui row"}>
            <div
              className={
                "ui eight wide left aligned column bottom aligned content"
              }
            >
              <h2>Nova Empresa</h2>
            </div>
            <div
              className={
                "ui four wide right aligned column bottom aligned content"
              }
            >
              <h5>Identificação</h5>
            </div>
            <div
              className={
                "ui four wide right aligned column bottom aligned content"
              }
            >
              <Link
                className={"ui mini icon right labeled button"}
                to={"/company/list"}
              >
                <i aria-hidden="true" className="reply icon" /> Voltar
              </Link>
            </div>
          </div>
        </div>
        <div className={"ui divider"} />
        <CompanyForm
          listDocumentValue={this.props.listDocumentValue}
          onSubmit={this.onSubmit}
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    // Document validation (CNPJ)
    listDocumentValue: Object.values(state.companies).map((company) => {
      return company.document;
    }),
  };
};

export default requireAuth(
  connect(mapStateToProps, {
    createCompany,
    fetchCompanies,
  })(CompanyCreate)
);
