import auth from "../apis/agentApi";
import { USER_LOGIN_SUCCESS, USER_LOGOUT } from "./types";
import { SubmissionError } from "redux-form";

export const userLoginSuccess = (token, userId, profile, isAdmin) => {
  return {
    type: USER_LOGIN_SUCCESS,
    token,
    userId,
    profile,
    isAdmin,
  };
};

export function userLoginAttempt(username, password) {
  return (dispatch) => {
    delete auth.defaults.headers.common.Authorization;
    return auth
      .post("/token", { username, password })

      .then((response) => {
        // console.log(response);
        const restrictUserProfile = response.data.user.profile.id;
        if (restrictUserProfile === 1) {
          auth.defaults.headers.common.Authorization = `Bearer ${response.data.token}`;

          dispatch(
            userLoginSuccess(
              response.data.token,
              response.data.user.id,
              response.data.user.profile.id,
              response.data.user.isAdmin
            )
          );
        } else {
          throw new SubmissionError({
            _error: "Sem Acesso",
          });
        }
      })
      .catch(() => {
        throw new SubmissionError({
          _error: "Usuário ou Senha Inválido",
        });
      });
  };
}

export const userLogout = () => {
  return {
    type: USER_LOGOUT,
  };
};
