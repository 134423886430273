import _ from "lodash";
import React from "react";
import requireAuth from "../auth/requireAuth";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import {
  fetchUser,
  editUser,
  fetchCompanies,
  fetchContas,
} from "../../actions";
import UserForm from "./UserForm";

class UserEdit extends React.Component {
  componentDidMount() {
    this.props.fetchUser(this.props.match.params.id);
    this.props.fetchCompanies();
  }

  onSubmit = (formValues) => {
    this.props.editUser(this.props.match.params.id, formValues);
  };

  render() {
    if (!this.props.user) {
      return <div className="ui active centered inline loader" />;
    }

    return (
      <div className={"ui container"}>
        <div className={"ui grid"}>
          <div className={"ui row"}>
            <div
              className={
                "ui eight wide left aligned column bottom aligned content"
              }
            >
              <h2>Edição do Usuário</h2>
            </div>
            <div
              className={
                "ui four wide right aligned column bottom aligned content"
              }
            >
              <h5>Identificação</h5>
            </div>
            <div
              className={
                "ui four wide right aligned column bottom aligned content"
              }
            >
              <Link
                className={"ui mini icon right labeled button"}
                to={"/user/list"}
              >
                <i aria-hidden="true" className="reply icon" /> Voltar
              </Link>
            </div>
          </div>
        </div>
        <div className={"ui divider"} />
        <UserForm
          initialValues={_.pick(
            this.props.user,
            "conta.fantasyName",
            "conta.id",
            "conta.[@id]",
            "companies",
            "name",
            "function",
            "phones",
            "cellphone",
            "email",
            "username",
            "isArt",
            "status"
          )}
          disable={true}
          onSubmit={this.onSubmit}
        />
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    user: state.users[ownProps.match.params.id],
    companies: Object.values(state.companies),
  };
};

export default requireAuth(
  connect(mapStateToProps, {
    editUser,
    fetchUser,
    fetchCompanies,
    fetchContas,
  })(UserEdit)
);
