import _ from "lodash";
import React from "react";
import { List } from "semantic-ui-react";
import requireAuth from "../auth/requireAuth";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { fetchContas, fetchConta } from "../../actions";

class ContaShow extends React.Component {
  componentDidMount() {
    this.props.fetchContas();
  }

  renderContaInfo() {
    const { contas } = this.props;

    const contaCompanies = _.map(_.map(contas.companies, "fantasyName"));
    const contaCNPJ = _.map(_.map(contas.companies, "document"));

    if (!contas) {
      return <div className="ui active centered inline loader" />;
    }

    return (
      <>
        <div className="ui header">
          <h3>
            <i aria-hidden="true" className="settings icon" />
            <span> Nome Grupo Empresarial:&nbsp; {contas.fantasyName}</span>
          </h3>
        </div>
        <br />
        <br />
        <div className="ui vertically grid">
          <div className="two column row">
            <div className="column">
              <div className="ui meta">
                <h5>Nome Fantasia:</h5>
              </div>
              <br />
              <div className="ui content">
                <ul>
                  {contaCompanies.map((item) => (
                    <List>
                      <List.Item>{item}</List.Item>
                    </List>
                  ))}
                </ul>
              </div>
              <div className="ui divider" />
            </div>
            <div className="column">
              <div className="ui meta">
                <h5>CNPJ:</h5>
              </div>
              <br />
              <div className="ui content">
                <ul>
                  {contaCNPJ.map((item) => (
                    <List>
                      <List.Item>{item}</List.Item>
                    </List>
                  ))}
                </ul>
              </div>
              <div className="ui divider" />
            </div>
          </div>
        </div>
      </>
    );
  }

  render() {
    return (
      <div className={"ui container"}>
        <div className={"ui grid"}>
          <div className={"ui row"}>
            <div
              className={
                "ui eight wide left aligned column bottom aligned content"
              }
            >
              <h2>Visualização do Grupo Empresarial</h2>
            </div>
            <div
              className={
                "ui four wide right aligned column bottom aligned content"
              }
            >
              <h5>Identificacão</h5>
            </div>
            <div
              className={
                "ui four wide right aligned column bottom aligned content"
              }
            >
              <Link
                className={"ui mini icon right labeled button"}
                to={"/conta/list"}
              >
                <i aria-hidden="true" className="reply icon" /> Voltar
              </Link>
            </div>
          </div>
        </div>
        <div className="ui divider" />
        {this.renderContaInfo()}
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    contas: state.contas[ownProps.match.params.id],
  };
};

export default requireAuth(
  connect(mapStateToProps, { fetchContas, fetchConta })(ContaShow)
);
