import React from "react";
import requireAuth from "../../components/auth/requireAuth";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";

import { PDFObject } from "react-pdfobject";

// Call from env
const apiUrl = process.env.REACT_APP_UPLOAD_URL;

const DropboxFileList = ({ files, onDelete }) => {
  // console.log("PEOPS", files);
  return (
    <div>
      <br />
      {files.map((uploadedFile) => (
        <div className="ui grid" key={uploadedFile.id}>
          <div className="ui row">
            <div className="ui eight wide column">
              <div className="ui middle aligned">
                <PDFObject url={uploadedFile.preview} />
              </div>
            </div>
            <div className="ui eight wide column">
              <div>
                <h4 className="ui teal header">{uploadedFile.name}</h4>
                <p className="ui description">
                  {uploadedFile.readableSize}{" "}
                  {!!uploadedFile.url && (
                    <button
                      className="small ui icon button"
                      onClick={() => onDelete(uploadedFile.id)}
                    >
                      <i className="ui red trash alternate icon" />
                    </button>
                  )}
                </p>
              </div>
              <div className="ui divider" />
              <div className="ui column middle aligned grid">
                <div className="row">
                  {!uploadedFile.uploaded && !uploadedFile.error && (
                    <div className="ui three wide column right floated">
                      <CircularProgressbar
                        value={uploadedFile.progress}
                        text={`${uploadedFile.progress}%`}
                        background
                        backgroundPadding={6}
                        styles={buildStyles({
                          backgroundColor: "#61a7d4",
                          textColor: "#fff",
                          pathColor: "#fff",
                          trailColor: "transparent",
                        })}
                      />
                    </div>
                  )}
                  {uploadedFile.url && (
                    <div className="ui three wide column right floated">
                      <a
                        href={apiUrl + `/${uploadedFile.url}`}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <i className="ui big olive print icon" />
                      </a>
                    </div>
                  )}
                  {uploadedFile.uploaded && (
                    <div className="ui three wide column">
                      <i className="ui big green check circle icon" />
                    </div>
                  )}
                  {uploadedFile.error && (
                    <div className="ui three wide column right floated">
                      <i className="ui big red times circle icon" />
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default requireAuth(DropboxFileList);
