//Authentication
export const USER_LOGIN_SUCCESS = "USER_LOGIN_SUCCESS";
export const USER_LOGOUT = "USER_LOGOUT";
// Users
export const SELECTED_USER = "SELECTED_USER";
export const CREATE_USER = "CREATE_USER";
export const FETCH_USERS = "FETCH_USERS";
export const FETCH_USER = "FETCH_USER";
export const EDIT_USER = "EDIT_USER";
export const DELETE_USER = "DELETE_USER";
// Company Users
export const SELECTED_COMPANY_USER = "SELECTED_COMPANY_USER";
export const CREATE_COMPANY_USER = "CREATE_COMPANY_USER";
export const FETCH_COMPANY_USERS = "FETCH_COMPANY_USERS";
export const FETCH_COMPANY_USER = "FETCH_COMPANY_USER";
export const EDIT_COMPANY_USER = "EDIT_COMPANY_USER";
export const DELETE_COMPANY_USER = "DELETE_COMPANY_USER";
// Conta
export const SELECTED_CONTA = "SELECTED_CONTA";
export const CREATE_CONTA = "CREATE_CONTA";
export const FETCH_CONTAS = "FETCH_CONTAS";
export const FETCH_CONTA = "FETCH_CONTA";
export const EDIT_CONTA = "EDIT_CONTA";
export const DELETE_CONTA = "DELETE_CONTA";
// Company
export const SELECTED_COMPANY = "SELECTED_COMPANY";
export const CREATE_COMPANY = "CREATE_COMPANY";
export const FETCH_COMPANIES = "FETCH_COMPANIES";
export const FETCH_COMPANY = "FETCH_COMPANY";
export const EDIT_COMPANY = "EDIT_COMPANY";
export const DELETE_COMPANY = "DELETE_COMPANY";
// Address
export const FETCH_ADDRESSES = "FETCH_ADDRESSES";
export const FETCH_ADDRESS = "FETCH_ADDRESS";
// Contract
export const SELECTED_CONTRACT = "SELECTED_CONTRACT";
export const CREATE_CONTRACT = "CREATE_CONTRACT";
export const FETCH_CONTRACTS = "FETCH_CONTRACTS";
export const FETCH_CONTRACT = "FETCH_CONTRACT";
export const EDIT_CONTRACT = "EDIT_CONTRACT";
export const DELETE_CONTRACT = "DELETE_CONTRACT";
// Report
export const SELECTED_REPORT = "SELECTED_REPORT";
export const CREATE_REPORT = "CREATE_REPORT";
export const FETCH_REPORTS = "FETCH_REPORTS";
export const FETCH_REPORT = "FETCH_REPORT";
export const EDIT_REPORT = "EDIT_REPORT";
export const DELETE_REPORT = "DELETE_REPORT";
// Order Services
export const SELECTED_ORDER_SERVICES = "SELECTED_ORDER_SERVICES";
export const CREATE_ORDER_SERVICES = "CREATE_ORDER_SERVICES";
export const FETCH_ORDER_SERVICESS = "FETCH_ORDER_SERVICESS";
export const FETCH_ORDER_SERVICES = "FETCH_ORDER_SERVICES";
export const EDIT_ORDER_SERVICES = "EDIT_ORDER_SERVICES";
export const DELETE_ORDER_SERVICES = "DELETE_ORDER_SERVICES";

// Support
export const FETCH_SEGMENT_TYPES = "FETCH_SEGMENT_TYPES";
export const FETCH_SERVICE_TYPES = "FETCH_SERVICE_TYPES";
export const FETCH_EQUIPMENT_TYPES = "FETCH_EQUIPMENT_TYPES";

// Pdf & Image
export const FILE_UPLOADED = "PDF_UPLOADED";
export const FILE_UPLOAD_REQUEST = "PDF_UPLOAD_REQUEST";
export const FILE_UPLOAD_ERROR = "PDF_UPLOAD_ERROR";
export const FILE_DELETE_REQUEST = "PDF_DELETE_REQUEST";
export const FILE_DELETED = "PDF_DELETED";

// CEP Search
export const CEP_SEARCHED = "CEP_SEARCHED";
export const CEP_SEARCH_ERROR = "CEP_SEARCH_ERROR";
export const CEP_SEARCH_REQUEST = "CEP_SEARCH_REQUEST";
