import _ from "lodash";
import React from "react";
import requireAuth from "../auth/requireAuth";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { Field, reduxForm } from "redux-form";
import { fetchCompanies, fetchContas, fetchUsers } from "../../actions";

import { createTextMask } from "redux-form-input-masks";
import { Checkbox } from "semantic-ui-react";

import { passwordGenerate } from "../../util/password-generate/passwordGenerate";

class CompanyUserForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      optsCompanies: [],
      showCompaniesFromConta: true,
    };
  }

  componentDidMount() {
    this.props.change("profile", `/api/profiles/1`);
    if (
      this.props.initialValues === undefined
        ? this.props.change("password", passwordGenerate())
        : ""
    )
      this.props.fetchCompanies();
    this.props.fetchContas();
    this.props.fetchUsers();
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      prevState.showCompaniesFromConta !== this.state.showCompaniesFromConta
    ) {
      this.setState({
        showCompaniesFromConta: true,
      });
    }
  }

  renderError({ touched, error }) {
    if (touched && error) {
      return (
        <div className={"ui tiny error message"}>
          <div className={"header"}>{error}</div>
        </div>
      );
    }
  }

  renderInput = ({ input, label, meta }) => {
    const className = `field ${meta.error && meta.touched ? "error" : ""}`;
    return (
      <div className={className}>
        <label>{label}</label>
        <input {...input} autoComplete={"off"} />
        {this.renderError(meta)}
      </div>
    );
  };

  renderInputPassword = ({ input, label, meta }) => {
    const className = `field ${meta.error && meta.touched ? "error" : ""}`;

    return (
      <div className={className}>
        {/*<label>{label}</label>*/}
        <input {...input} type="password" disabled={true} hidden={true} />
        {this.renderError(meta)}
      </div>
    );
  };

  renderCheckBox = ({ input, label, meta, value }) => {
    const className = `field ${meta.error && meta.touched ? "error" : ""}`;

    const isChecked = (() => {
      if (this.props.initialValues) {
        if (this.props.initialValues.isAdmin === true) {
          return true;
        } else {
          return false;
        }
      }
    })();

    return (
      <div className={className}>
        <label>{label}</label>
        <Checkbox
          {...input}
          type="checkbox"
          value={value}
          defaultChecked={isChecked}
        />
        {this.renderError(meta)}
      </div>
    );
  };

  renderCheckBoxStatus = ({ input, label, meta, value }) => {
    const className = `field ${meta.error && meta.touched ? "error" : ""}`;

    const isChecked = (() => {
      if (this.props.initialValues) {
        if (this.props.initialValues.status === true) {
          return true;
        } else {
          return false;
        }
      } else {
        return true;
      }
    })();

    return (
      <div className={className}>
        <label>{label}</label>
        <Checkbox
          {...input}
          type="checkbox"
          value={value}
          defaultChecked={isChecked}
        />
        {this.renderError(meta)}
      </div>
    );
  };

  onSubmit = (formValues) => {
    this.props.onSubmit(formValues);
  };

  render() {
    return (
      <form
        name="companyUserForm"
        className="ui tiny form error"
        onSubmit={this.props.handleSubmit(this.onSubmit)}
      >
        <div className="ui equal width fields">
          <Field
            type="text"
            name="name"
            component={this.renderInput}
            label="Nome"
            autoComplete="off"
          />
          <Field
            type="text"
            name="function"
            component={this.renderInput}
            label="Função"
            autoComplete="off"
          />
        </div>
        <div className={"ui equal width fields"}>
          <Field
            type="text"
            name="phones"
            component={this.renderInput}
            label="Telefone"
            autoComplete="off"
            {...userTelephoneMask}
          />{" "}
          <Field
            type="text"
            name="cellphone"
            component={this.renderInput}
            label="Celular"
            autoComplete="off"
            {...userCellphoneMask}
          />{" "}
          <Field
            type="text"
            name="email"
            component={this.renderInput}
            label="E-Mail"
            autoComplete="off"
          />
        </div>
        <div className="ui equal width fields">
          <Field
            type="text"
            name="username"
            component={this.renderInput}
            label="Nome de Usuário (Login)"
            autoComplete="off"
          />
          <Field
            name="password"
            component={this.renderInputPassword}
            label="Senha"
          />
          <Field
            name="isAdmin"
            component={this.renderCheckBox}
            label="Marque se o Usuário for Administrador ?"
            autoComplete="off"
          />
          <Field
            name="status"
            component={this.renderCheckBoxStatus}
            label="Usuário Ativo"
            autoComplete="off"
          />
        </div>
        <div className="ui container right aligned">
          <div className="ui buttons">
            <Link className="ui red button" to="/company_user/list">
              Cancelar
            </Link>
            <div className="or" data-text="ou" />
            <button type="submit" className="ui primary button">
              Salvar
            </button>
          </div>
        </div>{" "}
        <br />
        <br />
      </form>
    );
  }
}

const userTelephoneMask = createTextMask({
  pattern: "(99) 9999-9999",
  stripMask: false,
});

const userCellphoneMask = createTextMask({
  pattern: "(99) 99999-9999",
  stripMask: false,
});

const validate = (formValues) => {
  const errors = {};

  if (!formValues.name) {
    errors.name = "Campo Obrigatório, informe o nome";
  }

  if (!formValues.function) {
    errors.function = "Campo Obrigatório, informe a função do usuário";
  }

  if (!formValues.email) {
    errors.email = "Campo Obrigatório, informe o e-mail";
  } else if (
    !/^[A-Z0-9._%+-]+@[A-Z0-9]+\.([A-Z]{2,4}(?:\.[A-Z]{2})?)$/i.test(
      formValues.email
    )
  ) {
    errors.email = "E-mail inválido, favor corrigir";
  }

  if (!formValues.username) {
    errors.username = "Campo Obrigatório, informe o Login";
  } else if (formValues.username.length <= 6) {
    errors.username = "O Login deve ter 6 ou mais caracteres";
  }

  return errors;
};

const formWrapped = reduxForm({
  form: "companyUserForm",
  validate,
  enableReinitialize: true,
})(CompanyUserForm);

const mapStateToProps = (state) => {
  return {
    companies: _.reject(
      // reject id which is the company owner
      _.orderBy(Object.values(state.companies), "fantasyName", "asc"),
      {
        type: { id: 1 },
      }
    ),
    contas: _.orderBy(Object.values(state.contas), "fantasyName", "asc"),
    users: Object.values(state.users),
  };
};

export default requireAuth(
  connect(mapStateToProps, {
    fetchCompanies,
    fetchContas,
    fetchUsers,
  })(formWrapped)
);
