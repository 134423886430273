import _ from "lodash";
import React from "react";
import requireAuth from "../auth/requireAuth";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { Field, reduxForm } from "redux-form";
import { fetchCompanies, fetchContas } from "../../actions";

import Select from "react-select";
import { createTextMask } from "redux-form-input-masks";
import { Checkbox } from "semantic-ui-react";

import { passwordGenerate } from "../../util/password-generate/passwordGenerate";
import { value } from "lodash/seq";

class UserForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      optsCompanies: [],
      optsInitialCompanies: [],
      contaIdChosen: "",
      showCompaniesFromConta: true,
      editListTrigger: true,
    };
  }

  componentDidMount() {
    this.props.change("profile", `/api/profiles/2`);
    if (
      this.props.initialValues === undefined
        ? this.props.change("password", passwordGenerate())
        : ""
    )
      this.props.fetchContas();
    this.props.fetchCompanies();
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      prevState.showCompaniesFromConta !== this.state.showCompaniesFromConta
    ) {
      this.setState({
        showCompaniesFromConta: true,
      });
    }
    if (prevState.editListTrigger !== this.state.editListTrigger) {
      this.setState({
        editListTrigger: true,
      });
    }
  }

  filterCompanyGroup(idHidraConta) {
    const { contas } = this.props;

    const findNewListCompanies = [];
    _.forEach(contas, function (value) {
      if (value.id === parseInt(_.split(idHidraConta, "/", 4)[3])) {
        findNewListCompanies.push(value.companies);
      }
      return null;
    });

    this.setState({
      optsCompanies: findNewListCompanies[0],
      showCompaniesFromConta: !this.state.showCompaniesFromConta,
    });
  }

  keepCompanyList() {
    const contaCatch = this.props.initialValues.conta.id;
    const { contas } = this.props;

    const findCompaniesFromContaCatch = [];
    _.forEach(contas, function (value) {
      if (value.id === contaCatch) {
        findCompaniesFromContaCatch.push(value.companies);
      }
      return null;
    });

    this.setState({
      contaIdChosen: contaCatch,
      optsInitialCompanies: _.flatten(findCompaniesFromContaCatch),
      editListTrigger: !this.state.editListTrigger,
    });
  }

  renderError({ touched, error }) {
    // console.log("CONTA ID", this.props.initialValues.conta.id);
    if (touched && error) {
      return (
        <div className={"ui tiny error message"}>
          <div className={"header"}>{error}</div>
        </div>
      );
    }
  }

  renderInput = ({ input, label, meta }) => {
    const className = `field ${meta.error && meta.touched ? "error" : ""}`;
    return (
      <div className={className}>
        <label>{label}</label>
        <input {...input} autoComplete={"off"} />
        {this.renderError(meta)}
      </div>
    );
  };

  renderOptionConta() {
    return this.props.contas.map((conta) => ({
      value: conta.id,
      label: conta.fantasyName,
    }));
  }

  renderOptionContaInitialValue() {
    if (this.props.initialValues) {
      const { conta } = this.props.initialValues;
      return {
        value: conta.id,
        label: conta.fantasyName,
      };
    }
  }

  renderSelectConta = ({ input, value, label, meta, disabled }) => {
    const className = `field ${meta.error && meta.touched ? "error" : ""}`;
    const idHidraConta = input.value;

    const editValueConta = (() => {
      if (this.props.initialValues) {
        if (input.value.id !== undefined) {
          input.onChange(this.props.initialValues.conta["@id"]);
          return (value) => input.onChange(`/api/contas/${value.value}`);
        } else {
          return (value) => input.onChange(`/api/contas/${value.value}`);
        }
      } else {
        this.filterCompanyGroup(idHidraConta);
        return (value) => input.onChange(`/api/contas/${value.value}`);
      }
    })();

    return (
      <div className={className}>
        <label>{label}</label>
        <Select
          {...input}
          isDisabled={disabled}
          options={this.renderOptionConta()}
          value={value}
          defaultValue={this.renderOptionContaInitialValue()}
          placeholder={"Selecione o Grupo..."}
          onChange={editValueConta}
          onBlur={() => input.onBlur(input.value)}
        />
        {this.renderError(meta)}
      </div>
    );
  };

  renderOptionCompany() {
    if (this.state.optsCompanies !== undefined) {
      return this.state.optsCompanies.map((item) => ({
        value: item.id,
        label: item.fantasyName,
      }));
    }
    return null;
  }

  renderOptionCompanyFromState() {
    if (this.state.optsInitialCompanies !== undefined) {
      return this.state.optsInitialCompanies.map((item) => ({
        value: item.id,
        label: item.fantasyName,
      }));
    }
    return null;
  }

  renderOptionCompanyInitialValue() {
    if (this.props.initialValues) {
      //console.log('initialValues');
    //  console.log(this.props.initialValues);
    var newArray = [];
    var isObject = 0;

    if(!_.isArray(this.props.initialValues.companies)){
      isObject = 1;
      var j = 0;
      for (var i in this.props.initialValues.companies){
        if(this.props.initialValues.companies[i] != ''){
          newArray[j] = this.props.initialValues.companies[i];
          j++;
        }
      }
    }
    //console.log(isObject);
    //console.log(newArray);


    if(isObject){
      this.props.initialValues.companies = newArray;
    }

    const { companies } = this.props.initialValues;
      //_.isArray(this.props.initialValues.companies) ? this.props.initialValues : this.state.optsInitialCompanies;
    //  console.log('companies');
    //  console.log(companies);
      return companies.map((company) => ({
        value: company.id,
        label: company.fantasyName,
      }));
    }
  }

  renderSelectCompany = ({ input, value, label, meta, disabled }) => {
    const className = `field ${meta.error && meta.touched ? "error" : ""}`;

    const editValueCompany = (() => {
      if (this.props.initialValues) {
        this.keepCompanyList();

        if (this.state.editListTrigger === false) {
          input.onChange(_.map(this.props.initialValues.companies, "@id"));
        } else {
          var valueArrays = input.value;
          var valueArrays2 = [];
          //console.log(valueArrays);
          if(_.isObject(valueArrays[0])){
            valueArrays2 = _.map(valueArrays, "@id");
          }else{
            valueArrays2 = valueArrays;
          }

          return (valueArrays2) =>
            input.onChange(
              valueArrays2.map((valueArray2) => {
                return `/api/companies/${valueArray2.value}`;
              })
            );
        }
      } else {
        return (valueArrays) =>
          input.onChange(
            valueArrays.map((valueArray) => {
              return `/api/companies/${valueArray.value}`;
            })
          );
      }
    })();

    return (
      <div className={className}>
        <label>{label}</label>
        <Select
          {...input}
          options={
            !this.props.initialValues
              ? this.renderOptionCompany()
              : this.renderOptionCompanyFromState()
          }
          value={value}
          defaultValue={this.renderOptionCompanyInitialValue()}
          placeholder={"Selecione a empresa..."}
          onChange={editValueCompany}
          onBlur={() => input.onBlur(input.value)}
          isMulti
          isClearable={false}
        />
        {this.renderError(meta)}
      </div>
    );
  };

  renderInputPassword = ({ input, label, meta }) => {
    const className = `field ${meta.error && meta.touched ? "error" : ""}`;

    return (
      <div className={className}>
        {/*<label>{label}</label>*/}
        <input {...input} type="password" disabled={true} hidden={true} />
        {this.renderError(meta)}
      </div>
    );
  };

  renderCheckBox = ({ input, label, meta, value }) => {
    const className = `field ${meta.error && meta.touched ? "error" : ""}`;

    const isChecked = (() => {
      if (this.props.initialValues) {
        if (this.props.initialValues.isArt === true) {
          return true;
        } else {
          return false;
        }
      }
    })();

    return (
      <div className={className}>
        <label>{label}</label>
        <Checkbox
          {...input}
          type="checkbox"
          value={value}
          defaultChecked={isChecked}
        />
        {this.renderError(meta)}
      </div>
    );
  };

  renderCheckBoxStatus = ({ input, label, meta, value }) => {
    const className = `field ${meta.error && meta.touched ? "error" : ""}`;

    const isChecked = (() => {
      if (this.props.initialValues) {
        if (this.props.initialValues.status === true) {
          return true;
        } else {
          return false;
        }
      } else {
        return true;
      }
    })();

    return (
      <div className={className}>
        <label>{label}</label>
        <Checkbox
          {...input}
          type="checkbox"
          value={value}
          defaultChecked={isChecked}
        />
        {this.renderError(meta)}
      </div>
    );
  };

  onSubmit = (formValues) => {
    this.props.onSubmit(formValues);
  };

  render() {
    return (
      <form
        name={"userClient"}
        className={"ui tiny form error"}
        onSubmit={this.props.handleSubmit(this.onSubmit)}
      >
        <Field
          type={"text"}
          name={"conta"}
          component={this.renderSelectConta}
          label={"Grupo Empresarial"}
          disabled={this.props.disable}
        />
        {this.state.showCompaniesFromConta === true ? (
          <Field
            type={"text"}
            name={"companies"}
            component={this.renderSelectCompany}
            label={"Nome Fantasia da Empresa"}
          />
        ) : (
          ""
        )}
        <div className={"ui equal width fields"}>
          <Field
            type={"text"}
            name={"name"}
            component={this.renderInput}
            label={"Nome"}
            autoComplete="off"
          />
          <Field
            type={"text"}
            name={"function"}
            component={this.renderInput}
            label={"Função"}
            autoComplete="off"
          />
        </div>
        <div className={"ui equal width fields"}>
          <Field
            type={"text"}
            name={"phones"}
            component={this.renderInput}
            label={"Telefone"}
            autoComplete="off"
            {...userTelephoneMask}
          />
          <Field
            type={"text"}
            name={"cellphone"}
            component={this.renderInput}
            label={"Celular"}
            autoComplete="off"
            {...userCellphoneMask}
          />
          <Field
            type={"text"}
            name={"email"}
            component={this.renderInput}
            label={"E-Mail"}
            autoComplete="off"
          />
        </div>
        <div className="ui equal width fields">
          <Field
            type="text"
            name="username"
            component={this.renderInput}
            label="Nome de Usuário (Login)"
            autoComplete="off"
          />
          <Field
            name="password"
            component={this.renderInputPassword}
            label="Senha"
          />
          <Field
            name="isArt"
            component={this.renderCheckBox}
            label="Marque se o Usuário for RT ?"
            autoComplete="off"
          />
          <Field
            name="status"
            component={this.renderCheckBoxStatus}
            label="Usuário Ativo"
            autoComplete="off"
          />
        </div>
        <div className={"ui container right aligned"}>
          <div className={"ui buttons"}>
            <Link className={"ui red button"} to={"/user/list"}>
              Cancelar
            </Link>
            <div className={"or"} data-text={"ou"} />
            <button type="submit" className={"ui primary button"}>
              Salvar
            </button>
          </div>
        </div>
        <br />
        <br />
      </form>
    );
  }
}

const userTelephoneMask = createTextMask({
  pattern: "(99) 9999-9999",
  stripMask: false,
});

const userCellphoneMask = createTextMask({
  pattern: "(99) 99999-9999",
  stripMask: false,
});

const validate = (formValues) => {
  const errors = {};

  if (!formValues.conta) {
    errors.conta = "Campo Obrigatório, escolha o Grupo Empresarial";
  }

  if (!formValues.companies) {
    errors.companies = "Campo Obrigatório, escolha a empresa";
  }

  if (!formValues.name) {
    errors.name = "Campo Obrigatório, informe o nome";
  }

  if (!formValues.function) {
    errors.function = "Campo Obrigatório, informe a função do usuário";
  }

  if (!formValues.email) {
    errors.email = "Campo Obrigatório, informe o e-mail";
  } else if (
    !/^[A-Z0-9._%+-]+@[A-Z0-9]+\.([A-Z]{2,4}(?:\.[A-Z]{2})?)$/i.test(
      formValues.email
    )
  ) {
    errors.email = "E-mail inválido, favor corrigir";
  }

  if (!formValues.username) {
    errors.username = "Campo Obrigatório, informe o Login";
  } else if (formValues.username.length <= 6) {
    errors.username = "O Login deve ter 6 ou mais caracteres";
  }

  return errors;
};

const formWrapped = reduxForm({
  form: "userForm",
  validate,
  enableReinitialize: true,
})(UserForm);

const mapStateToProps = (state) => {
  return {
    companies: _.reject(
      // reject id which is the company owner
      _.orderBy(Object.values(state.companies), "fantasyName", "asc"),
      {
        type: { id: 1 },
      }
    ),
    contas: _.orderBy(Object.values(state.contas), "fantasyName", "asc"),
  };
};

export default requireAuth(
  connect(mapStateToProps, {
    fetchCompanies,
    fetchContas,
  })(formWrapped)
);
