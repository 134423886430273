import _ from "lodash";
import React from "react";
import requireAuth from "../auth/requireAuth";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import {
  fetchCompanyUser,
  editCompanyUser,
  fetchCompanies,
  fetchContas,
  fetchUsers,
} from "../../actions";

import CompanyUserForm from "./CompanyUserForm";

class CompanyUserEdit extends React.Component {
  componentDidMount() {
    this.props.fetchCompanyUser(this.props.match.params.id);
    this.props.fetchUsers();
  }

  onSubmit = (formValues) => {
    this.props.editCompanyUser(this.props.match.params.id, formValues);
  };

  render() {
    if (!this.props.user) {
      return <div className="ui active centered inline loader" />;
    }

    return (
      <div className={"ui container"}>
        <div className={"ui grid"}>
          <div className={"ui row"}>
            <div
              className={
                "ui eight wide left aligned column bottom aligned content"
              }
            >
              <h2>G7 Serviços de Proteção Radiológico - Edição do Usuário</h2>
            </div>
            <div
              className={
                "ui four wide right aligned column bottom aligned content"
              }
            >
              <h5>Identificação</h5>
            </div>
            <div
              className={
                "ui four wide right aligned column bottom aligned content"
              }
            >
              <Link
                className={"ui mini icon right labeled button"}
                to={"/company_user/list"}
              >
                <i aria-hidden="true" className="reply icon" /> Voltar
              </Link>
            </div>
          </div>
        </div>
        <div className={"ui divider"} />
        <CompanyUserForm
          // compMap={_.map(this.props.user.companies, "fantasyName")}
          initialValues={_.pick(
            this.props.user,
            "conta.fantasyName",
            "conta.id",
            "conta.[@id]",
            "companies",
            "name",
            "function",
            "phones",
            "cellphone",
            "email",
            "username",
            "isAdmin",
            "status"
          )}
          disable={true}
          onSubmit={this.onSubmit}
        />
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    user: state.users[ownProps.match.params.id],
    users: Object.values(state.users),
  };
};

export default requireAuth(
  connect(mapStateToProps, {
    fetchCompanyUser,
    editCompanyUser,
    fetchCompanies,
    fetchContas,
    fetchUsers,
  })(CompanyUserEdit)
);
