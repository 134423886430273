import _ from "lodash";
import React from "react";
import requireAuth from "../auth/requireAuth";
import { connect } from "react-redux";
import { fetchUsers, fetchCompanies } from "../../actions";

import AccountForm from "./AccountForm";

class AccountShow extends React.Component {
  componentDidMount() {
    this.props.fetchUsers();
    this.props.fetchCompanies();
  }

  renderUserInfo() {
    const { userData, currentUserId } = this.props;

    if (!userData) {
      return <div className="ui active centered inline loader" />;
    }

    return userData.map((user) => {
      if (user.id === currentUserId) {
        return (
          <React.Fragment key={user.id}>
            <div className="ui header">
              <h5>
                <i aria-hidden="true" className="settings icon" />
                <span className="ui header">Nome:&nbsp; {user.name}</span>
              </h5>
            </div>
            <div>
              <p className="ui content">
                Seu Login é: <strong>{user.username}</strong>
              </p>
              <p className="ui content">
                Seu e-mail cadastrado: <strong>{user.email}</strong>
              </p>
              <p className="ui content">
                Seu telefone: <strong>{user.phones}</strong>
              </p>
            </div>
            <br />
            <br />
          </React.Fragment>
        );
      }

      return null;
    });
  }

  render() {
    return (
      <div className="ui container">
        <div className={"ui grid"}>
          <div className={"ui row"}>
            <div
              className={
                "ui eight wide left aligned column bottom aligned content"
              }
            >
              <h2>Minha Conta</h2>
            </div>
            <div
              className={
                "ui four wide right aligned column bottom aligned content"
              }
            >
              <h5>Identificacão</h5>
            </div>
            <div
              className={
                "ui four wide right aligned column bottom aligned content"
              }
            />
          </div>
        </div>
        <div className="ui divider" />

        <div className="ui two column divided grid">
          <div className="ui row">
            <div className="ui column">{this.renderUserInfo()}</div>
            <AccountForm />
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    userData: Object.values(state.users),
    currentUserId: state.auth.userId,
    companyUser: _.find(Object.values(state.companies), "currentUserId"),
  };
};

export default requireAuth(
  connect(mapStateToProps, { fetchUsers, fetchCompanies })(AccountShow)
);
