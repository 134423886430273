import _ from "lodash";
import React from "react";
import requireAuth from "../auth/requireAuth";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { Field, reduxForm } from "redux-form";
import Select from "react-select";
import fileSize from "filesize";
import { Checkbox } from "semantic-ui-react";
import DropboxUpload from "../../util/dropzone/DropboxUpload";
import DropboxFileList from "../../util/dropzone/DropboxFileList";

import { PDFObject } from "react-pdfobject";

import agentApi from "../../apis/agentApi";

import DayPickerInput from "react-day-picker/DayPickerInput";
import "react-day-picker/lib/style.css";

import MomentLocaleUtils, {
  formatDate,
  parseDate,
} from "react-day-picker/moment";

import "moment/locale/pt-br";

import {
  fetchCompanies,
  fetchEquipmentTypes,
  fetchServiceTypes,
  // fileUpload,
} from "../../actions";

// Call from env
const apiUrl = process.env.REACT_APP_UPLOAD_URL;

class ContractForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      value: null,
      selectedDay: undefined,
      file: null,
      uploadedFiles: [],
    };
  }

  componentDidMount() {
    this.props.fetchCompanies();
    this.props.fetchServiceTypes();
    this.props.fetchEquipmentTypes();
  }

  renderError({ error, touched }) {
    if (touched && error) {
      return (
        <div className={"ui tiny error message"}>
          <div className={"header"}>{error}</div>
        </div>
      );
    }
  }

  renderInput = ({ input, label, meta }) => {
    const className = `field ${meta.error && meta.touched ? "error" : ""}`;
    return (
      <div className={className}>
        <label>{label}</label>
        <input {...input} autoComplete="off" />
        {this.renderError(meta)}
      </div>
    );
  };

  renderOptionCompany() {
    // console.log(this.props.companies);
    return this.props.companies.map((company) => ({
      value: company.id,
      label: company.fantasyName,
    }));
  }

  renderOptionCompanyInitialValue() {
    if (this.props.initialValues) {
      const { company } = this.props.initialValues;
      return {
        value: company.id,
        label: company.fantasyName,
      };
    }
  }

  renderSelectCompany = ({ input, value, label, meta }) => {
    const className = `field ${meta.error && meta.touched ? "error" : ""}`;

    const editValueCompany = (() => {
      if (this.props.initialValues) {
        // ++++ If Come from Edit
        if (input.value.id !== undefined) {
          input.onChange(this.props.initialValues.company["@id"]);
          return (value) => input.onChange(`/api/companies/${value.value}`);
        } else {
          return (value) => input.onChange(`/api/companies/${value.value}`);
        }
      } else {
        // ++++ If come from Create
        return (value) => input.onChange(`/api/companies/${value.value}`);
      }
    })();

    return (
      <div className={className}>
        <label>{label}</label>
        <Select
          {...input}
          options={this.renderOptionCompany()}
          value={value}
          defaultValue={this.renderOptionCompanyInitialValue()}
          placeholder={"Selecione a empresa..."}
          onChange={editValueCompany}
          onBlur={() => input.onBlur(input.value)}
        />
        {this.renderError(meta)}
      </div>
    );
  };

  renderInputStartDate = ({ input, label, meta, type }) => {
    const className = `field ${meta.error && meta.touched ? "error" : ""}`;

    const formatValue = (() => {
      if (this.props.initialValues) {
        const { startDate } = this.props.initialValues;
        return formatDate(startDate, "L", "pt-br");
      }
    })();

    return (
      <div className={className}>
        <label>{label}</label>
        <DayPickerInput
          {...input}
          type={type}
          onDayChange={input.onChange}
          selectedDay={this.state.selectedDay}
          formatDate={formatDate}
          parseDate={parseDate}
          value={formatValue}
          format="L"
          placeholder={`${formatDate(new Date(), "L", "pt-br")}`}
          dayPickerProps={{
            locale: "pt-br",
            localeUtils: MomentLocaleUtils,
          }}
        />
        {this.renderError(meta)}
      </div>
    );
  };

  renderInputEndDate = ({ input, label, meta, type }) => {
    const className = `field ${meta.error && meta.touched ? "error" : ""}`;

    const formatValue = (() => {
      if (this.props.initialValues) {
        const { endDate } = this.props.initialValues;
        return formatDate(endDate, "L", "pt-br");
      }
    })();

    return (
      <div className={className}>
        <label>{label}</label>
        <DayPickerInput
          {...input}
          type={type}
          onDayChange={input.onChange}
          selectedDay={this.state.selectedDay}
          formatDate={formatDate}
          parseDate={parseDate}
          value={formatValue}
          format="L"
          placeholder={`${formatDate(new Date(), "L", "pt-br")}`}
          dayPickerProps={{
            locale: "pt-br",
            localeUtils: MomentLocaleUtils,
          }}
        />
        {this.renderError(meta)}
      </div>
    );
  };

  renderCheckBox = ({ input, label, meta, value }) => {
    const className = `field ${meta.error && meta.touched ? "error" : ""}`;

    const isChecked = (() => {
      if (this.props.initialValues) {
        if (this.props.initialValues.isArchived === true) {
          return true;
        } else {
          return false;
        }
      }
    })();

    return (
      <div className={className}>
        <label>{label}</label>
        <Checkbox
          {...input}
          type="checkbox"
          value={value}
          defaultChecked={isChecked}
        />
        {this.renderError(meta)}
      </div>
    );
  };

  renderInputTextArea = ({ input, label, meta }) => {
    const className = `field ${meta.error && meta.touched ? "error" : ""}`;
    return (
      <div className={className}>
        <label>{label}</label>
        <textarea rows="4" {...input} autoComplete="off" />
        {this.renderError(meta)}
      </div>
    );
  };

  renderInputFile = ({ input, meta }) => {
    const className = `field ${meta.error && meta.touched ? "error" : ""}`;

    const { uploadedFiles } = this.state;

    const urlValue = uploadedFiles.map((file) => file.url).toString();

    return (
      <div className={className}>
        <input
          {...input}
          value={this.props.change("file", urlValue)}
          hidden="on"
          autoComplete="off"
        />
        {this.renderError(meta)}
      </div>
    );
  };

  handleUpload = (files) => {
    const uploadedFiles = files.map((file) => ({
      file,
      id: _.uniqueId(),
      name: file.name,
      readableSize: fileSize(file.size),
      preview: URL.createObjectURL(file),
      progress: 0,
      uploaded: false,
      error: false,
      url: null,
    }));

    this.setState({
      uploadedFiles: this.state.uploadedFiles.concat(uploadedFiles),
    });

    uploadedFiles.forEach(this.processUpload);
  };

  updateFile = (id, data) => {
    this.setState({
      uploadedFiles: this.state.uploadedFiles.map((uploadedFile) => {
        return id === uploadedFile.id
          ? { ...uploadedFile, ...data }
          : uploadedFile;
      }),
    });
  };

  processUpload = (uploadedFile) => {
    const data = new FormData();

    data.append("file", uploadedFile.file, uploadedFile.name);

    agentApi
      .post("/api/upload", data, {
        onUploadProgress: (e) => {
          const progress = parseInt(Math.round((e.loaded * 100) / e.total));

          this.updateFile(uploadedFile.id, {
            progress,
          });
        },
      })
      .then((response) => {
        this.updateFile(uploadedFile.id, {
          uploaded: true,
          url: response.data.file,
        });
      })

      .catch(() => {
        this.updateFile(uploadedFile.id, {
          error: true,
        });
      });
  };

  handleDelete = (id) => {
    this.setState({
      uploadedFiles: this.state.uploadedFiles.filter((file) => file.id !== id),
    });
  };

  onSubmit = (formValues) => {
    this.props.onSubmit(formValues);
  };

  render() {
    const { uploadedFiles } = this.state;
    const { pristine, submitting, initialValues } = this.props;

    const urlValue = uploadedFiles.map((file) => file.url).toString();

    return (
      <form
        name="contract"
        className="ui form error"
        onSubmit={this.props.handleSubmit(this.onSubmit)}
      >
        <Field
          type="text"
          name="company"
          component={this.renderSelectCompany}
          label="Nome Fantasia da Empresa"
        />
        <Field
          type="text"
          name="title"
          component={this.renderInput}
          label="Título do Contrato"
        />
        <div className={"ui grid"}>
          <div className="eight wide column ">
            <div className="row">
              <Field
                type="date"
                name="startDate"
                label="Inicio do Contrato"
                component={this.renderInputStartDate}
              />
            </div>
          </div>
          <div className="eight wide column ">
            <div className="row">
              <Field
                type="date"
                name="endDate"
                label="Válido Até"
                component={this.renderInputEndDate}
              />
            </div>
          </div>
        </div>
        <br />
        <Field
          type="text"
          name="observation"
          component={this.renderInputTextArea}
          label="Observações"
        />
        <Field
          name="isArchived"
          component={this.renderCheckBox}
          label="Marque se desejar arquivar o contrato."
          autoComplete="off"
        />
        {!!uploadedFiles.length && (
          <DropboxFileList files={uploadedFiles} onDelete={this.handleDelete} />
        )}
        <br />
        <br />
        {!!initialValues && (
          <div className="ui eight wide column">
            <div className="ui middle aligned">
              <PDFObject url={apiUrl + `/${initialValues.file}`} />
            </div>
          </div>
        )}
        <br />
        <Field
          name="file"
          component={this.renderInputFile}
          files={uploadedFiles}
        />
        {!!uploadedFiles.length || (
          <DropboxUpload onUpload={this.handleUpload} />
        )}
        <br />
        <div className="ui container right aligned">
          <div className="ui buttons">
            <Link className="ui red button" to={"/contract/list"}>
              Cancelar
            </Link>
            <div className={"or"} data-text={"ou"} />
            {!initialValues ? (
              <button
                className={"ui primary button"}
                disabled={urlValue.length === 0 || pristine || submitting}
              >
                Salvar
              </button>
            ) : (
              <button className={"ui primary button"} disabled={submitting}>
                Salvar
              </button>
            )}
          </div>
        </div>
      </form>
    );
  }
}

const validate = (formValues) => {
  const errors = {};

  if (!formValues.company) {
    errors.company = "Campo Obrigatório, escolha a empresa";
  }

  if (!formValues.title) {
    errors.title = "Campo Obrigatório, escolha de um nome ou seu contrato.";
  }

  return errors;
};

const formWrapped = reduxForm({
  form: "contractForm",
  enableReinitialize: true,
  validate,
})(ContractForm);

const mapStateToProps = (state) => {
  return {
    companies: _.reject(
      // reject id which is the owner
      _.orderBy(Object.values(state.companies), "fantasyName", "asc"),
      {
        type: { id: 1 },
      }
    ),
    // service_types: Object.values(state.service_types),
    // equipment_types: Object.values(state.equipment_types),
    file: _.map(Object.values(state.file), "data.file"),
  };
};

export default requireAuth(
  connect(mapStateToProps, {
    fetchCompanies,
    fetchServiceTypes,
    fetchEquipmentTypes,
    // fileUpload,
  })(formWrapped)
);
