import _ from "lodash";
import React from "react";
import requireAuth from "../auth/requireAuth";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { fetchUser } from "../../actions";
import { List } from "semantic-ui-react";

class UserShow extends React.Component {
  renderUserClientInfo() {
    const { userClientData } = this.props;

    const contaName = _.map(_.map(userClientData.companies, "fantasyName"));

    if (!userClientData) {
      return <div className="ui active centered inline loader" />;
    }

    console.log(this.props);

    return (
      <>
        <div className="ui header">
          <h3>
            <i aria-hidden="true" className="settings icon" />
            <span> Nome:&nbsp; {userClientData.name}</span>
          </h3>
        </div>
        <div className="ui vertically grid">
          <div className="two column row">
            <div className="column">
              <div className="ui meta">
                <h5>Grupo Empresarial:</h5>
              </div>
              <br />
              <div className="ui content">
                <h4>{userClientData.conta.fantasyName}</h4>
              </div>
              <div className="ui divider" />
            </div>
            <div className="column">
              <div className="ui meta">
                <h5>Empresa Nome Fantasia:</h5>
              </div>
              <br />
              <div className="ui content">
                <ul>
                  {contaName.map((item) => (
                    <List>
                      <List.Item>{item}</List.Item>
                    </List>
                  ))}
                </ul>
              </div>
              <div className="ui divider" />
            </div>
          </div>
          <div className="two column row">
            <div className="column">
              <div className="ui meta">
                <h5>Login:</h5>
              </div>
              <br />
              <div className="ui content">
                <h4>{userClientData.username}</h4>
              </div>
              <div className="ui divider" />
            </div>
            <div className="column">
              <div className="ui meta">
                <h5>É RT ?:</h5>
              </div>
              <br />
              <div className="ui content">
                <h4>{userClientData.isArt === true ? "Sim" : "Não"}</h4>
              </div>
              <div className="ui divider" />
            </div>
          </div>
          <div className="three column row">
            <div className="column">
              <div className="ui meta">
                <h5>E-mail:</h5>
              </div>
              <br />
              <div className="ui content">
                <h4>{userClientData.email}</h4>
              </div>
              <div className="ui divider" />
            </div>

            <div className="column">
              <div className="ui meta">
                <h5>Telefone:</h5>
              </div>
              <br />
              <div className="ui content">
                <h4>{userClientData.phones}</h4>
              </div>
              <div className="ui divider" />
            </div>

            <div className="column">
              <div className="ui meta">
                <h5>Celular:</h5>
              </div>
              <br />
              <div className="ui content">
                <h4>{userClientData.cellphone}</h4>
              </div>
              <div className="ui divider" />
            </div>
          </div>
        </div>
      </>
    );
  }

  render() {
    return (
      <div className={"ui container"}>
        <div className={"ui grid"}>
          <div className={"ui row"}>
            <div
              className={
                "ui eight wide left aligned column bottom aligned content"
              }
            >
              <h2>Visualização do Usário</h2>
            </div>
            <div
              className={
                "ui four wide right aligned column bottom aligned content"
              }
            >
              <h5>Identificacão</h5>
            </div>
            <div
              className={
                "ui four wide right aligned column bottom aligned content"
              }
            >
              <Link
                className={"ui mini icon right labeled button"}
                to={"/user/list"}
              >
                <i aria-hidden="true" className="reply icon" /> Voltar
              </Link>
            </div>
          </div>
        </div>
        <div className="ui divider" />
        {this.renderUserClientInfo()}
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return { userClientData: state.users[ownProps.match.params.id] };
};

export default requireAuth(connect(mapStateToProps, { fetchUser })(UserShow));
