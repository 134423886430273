import companyUsers from "../apis/agentApi";
import {
  SELECTED_COMPANY_USER,
  CREATE_COMPANY_USER,
  FETCH_COMPANY_USERS,
  FETCH_COMPANY_USER,
  EDIT_COMPANY_USER,
  DELETE_COMPANY_USER,
} from "./types";
import history from "../history";

// Start User
export const selectedCompanyUser = (companyUser) => {
  return {
    type: SELECTED_COMPANY_USER,
    payload: companyUser,
  };
};

export const createCompanyUser = (formValues) => async (dispatch) => {
  const response = await companyUsers.post("api/users", formValues);

  dispatch({ type: CREATE_COMPANY_USER, payload: response.data });
  history.push("/company_user/list");
};

export const fetchCompanyUsers = () => async (dispatch) => {
  const response = await companyUsers.get("/api/users");

  dispatch({ type: FETCH_COMPANY_USERS, payload: response.data });
};

export const fetchCompanyUser = (id) => async (dispatch) => {
  const response = await companyUsers.get(`/api/users/${id}`);

  dispatch({ type: FETCH_COMPANY_USER, payload: response.data });
};

export const editCompanyUser = (id, formValues) => async (dispatch) => {
  // Customize axios instance for patch
  let axiosConfig = {
    headers: {
      "Content-Type": "application/merge-patch+json",
    },
  };
  // End //

  const response = await companyUsers.patch(
    `/api/users/${id}`,
    formValues,
    axiosConfig
  );

  dispatch({ type: EDIT_COMPANY_USER, payload: response.data });
  history.push("/company_user/list");
};

export const deleteCompanyUser = (id) => async (dispatch) => {
  await companyUsers.delete(`/api/users/${id}`);

  dispatch({ type: DELETE_COMPANY_USER, payload: id });
};
