import contas from "../apis/agentApi";
import {
  SELECTED_CONTA,
  CREATE_CONTA,
  FETCH_CONTAS,
  FETCH_CONTA,
  EDIT_CONTA,
  DELETE_CONTA,
} from "./types";
import history from "../history";

// Start Conta
export const selectedConta = (conta) => {
  return {
    type: SELECTED_CONTA,
    payload: conta,
  };
};

export const createConta = (formValues) => async (dispatch) => {
  const response = await contas.post("api/contas", formValues);

  dispatch({ type: CREATE_CONTA, payload: response.data });
  history.push("/conta/list");
};

export const fetchContas = () => async (dispatch) => {
  const response = await contas.get("api/contas");

  dispatch({ type: FETCH_CONTAS, payload: response.data });
};

export const fetchConta = (id) => async (dispatch) => {
  const response = await contas.get(`api/contas/${id}`);

  dispatch({ type: FETCH_CONTA, payload: response.data });
};

export const editConta = (id, formValues) => async (dispatch) => {
  // Customize axios instance for patch
  let axiosConfig = {
    headers: {
      "Content-Type": "application/merge-patch+json",
    },
  };
  // End //

  const response = await contas.patch(
    `api/contas/${id}`,
    formValues,
    axiosConfig
  );

  dispatch({ type: EDIT_CONTA, payload: response.data });
  history.push("/conta/list");
};

export const deleteConta = (id) => async (dispatch) => {
  await contas.delete(`api/contas/${id}`);

  dispatch({ type: DELETE_CONTA, payload: id });
};
