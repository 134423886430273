import _ from "lodash";
import React from "react";
import requireAuth from "../auth/requireAuth";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import ReactTable from "react-table-v6";
import {
  fetchCompanyUsers,
  selectedCompanyUser,
  fetchContas,
  fetchUsers,
} from "../../actions";

import "react-table-v6/react-table.css";

class CompanyUserTableGrid extends React.Component {
  componentDidMount() {
    if (this.props.usersCompany.length === 0) {
      this.props.fetchCompanyUsers();
    }
    this.props.fetchContas();
    this.props.fetchUsers();
  }

  render() {
    const { usersCompany } = this.props;

    const onRowClick = (state, rowInfo) => {
      return {
        onClick: (e) => {
          this.props.selectedCompanyUser(rowInfo.original);
        },
      };
    };

    return (
      <>
        <div className={"twelve wide column left aligned"}>
          <h1>G7 Serviços de Proteção Radiológico - Usuários</h1>
        </div>
        <div className={"ui divider"} />
        <Link
          className="ui small icon primary right floated right labeled button"
          to={"/company_user/new"}
        >
          <i aria-hidden="true" className="building icon" />
          Novo Usuário
        </Link>
        <br />
        <br />
        <br />
        <ReactTable
          keyField="id"
          getTdProps={onRowClick}
          filterable
          showPagination={true}
          minRows={0}
          previousText="<"
          nextText=">"
          rowsText=""
          pageText="Página"
          ofText="de"
          noDataText="Nenhuma Informação"
          loadingText="Buscando dados..."
          defaultFilterMethod={(filter, row) =>
            String(row[filter.id])
              .toLowerCase()
              .indexOf(filter.value.toLowerCase()) > -1
          }
          defaultPageSize={10}
          className="-striped -highlight"
          data={usersCompany}
          resolveData={(data) => data.map((row) => row)}
          columns={[
            {
              Header: "Nome",
              accessor: "name",
            },
            {
              Header: "Função",
              accessor: "function",
            },
            {
              Header: "E-mail",
              accessor: "email",
            },
            {
              Header: "Login",
              accessor: "username",
            },
            {
              Header: "Ações",
              columns: [
                {
                  accessor: "id",
                  filterable: false,
                  maxWidth: 80,
                  Cell: (e) => (
                    <div className="ui column centered">
                      <Link
                        className="ui mini icon grey button"
                        to={`/company_user/show/${e.value}`}
                      >
                        <i aria-hidden="true" className="file icon" />
                      </Link>
                      <Link
                        className="ui mini icon yellow button"
                        to={`/company_user/edit/${e.value}`}
                      >
                        <i aria-hidden="true" className="edit icon" />
                      </Link>
                    </div>
                  ),
                },
              ],
            },
          ]}
        />
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    usersCompany: _.filter(
      _.orderBy(Object.values(state.companyUsers), "id", "desc"),
      {
        profile: "/api/profiles/1",
      }
    ),
    contas: Object.values(state.contas),
    users: Object.values(state.users),
    selectedUser: state.selectedUser,
  };
};

export default requireAuth(
  requireAuth(
    connect(mapStateToProps, {
      selectedCompanyUser,
      fetchCompanyUsers,
      fetchContas,
      fetchUsers,
    })(CompanyUserTableGrid)
  )
);
