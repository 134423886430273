import _ from "lodash";
import React from "react";
import requireAuth from "../auth/requireAuth";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { editContract, fetchContract, fetchCompanies } from "../../actions";
import ContractForm from "./ContractForm";

class ContractEdit extends React.Component {
  componentDidMount() {
    this.props.fetchContract(this.props.match.params.id);
  }

  onSubmit = (formValues) => {
    if (formValues.file == "") {
      formValues.file = this.props.contract.file;
    }
    this.props.editContract(this.props.match.params.id, formValues);
  };

  render() {
    if (!this.props.contract) {
      return <div className="ui active centered inline loader" />;
    }

    return (
      <div className={"ui container"}>
        <div className={"ui grid"}>
          <div className={"ui row"}>
            <div
              className={
                "ui eight wide left aligned column bottom aligned content"
              }
            >
              <h2>Edição de Contrato</h2>
            </div>
            <div
              className={
                "ui four wide right aligned column bottom aligned content"
              }
            >
              <h5>Identificação</h5>
            </div>
            <div
              className={
                "ui four wide right aligned column bottom aligned content"
              }
            >
              <Link
                className={"ui mini icon right labeled button"}
                to={"/contract/list"}
              >
                <i aria-hidden="true" className="reply icon" /> Voltar
              </Link>
            </div>
          </div>
        </div>
        <div className={"ui divider"} />
        <ContractForm
          initialValues={_.pick(
            this.props.contract,
            "company.fantasyName",
            "company[@id]",
            "company.document",
            "company.id",
            "title",
            "startDate",
            "endDate",
            "services.id",
            "equipments.id",
            "observation",
            "file",
            "isArchived"
          )}
          onSubmit={this.onSubmit}
        />
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    contract: state.contracts[ownProps.match.params.id],
  };
};

export default requireAuth(
  connect(mapStateToProps, {
    editContract,
    fetchContract,
    fetchCompanies,
  })(ContractEdit)
);
