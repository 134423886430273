import React from "react";
import requireAuth from "../auth/requireAuth";
import CompanyTableGrid from "./CompanyTableGrid";
import ReportTableGrid from "../reports/ReportTableGrid";

class CompanyList extends React.Component {
  render() {
    return (
      <div className="ui centered grid">
        <div className="ui fifteen wide column">
          <CompanyTableGrid />
        </div>
      </div>
    );
  }
}

export default requireAuth(CompanyList);
