import React from "react";
import { Router, Route, Switch } from "react-router-dom";
import { connect } from "react-redux";
import { userLogout } from "../actions";

import history from "../history";

import Header from "./Header";
import Home from "./Home";
import Logout from "./Logout";
import DashboardShow from "./dashboard/DashboardShow";

import AccountShow from "./accounts/AccountShow";

import UserCreate from "./users/UserCreate";
import UserList from "./users/UserList";
import UserEdit from "./users/UserEdit";
import UserDelete from "./users/UserDelete";
import UserShow from "./users/UserShow";

import ContaCreate from "./contas/ContaCreate";
import ContaList from "./contas/ContaList";
import ContaEdit from "./contas/ContaEdit";
import ContaDelete from "./contas/ContaDelete";
import ContaShow from "./contas/ContaShow";

import CompanyCreate from "./companies/CompanyCreate";
import CompanyList from "./companies/CompanyList";
import CompanyEdit from "./companies/CompanyEdit";
import CompanyDelete from "./companies/CompanyDelete";
import CompanyShow from "./companies/CompanyShow";

import ContractCreate from "./contratcs/ContractCreate";
import ContractList from "./contratcs/ContractList";
import ContractEdit from "./contratcs/ContractEdit";
import ContractDelete from "./contratcs/ContractDelete";
import ContractShow from "./contratcs/ContractShow";

import ReportCreate from "./reports/ReportCreate";
import ReportList from "./reports/ReportList";
import ReportEdit from "./reports/ReportEdit";
import ReportDelete from "./reports/ReportDelete";
import ReportShow from "./reports/ReportShow";

import OrderServiceCreate from "./orderservices/OrderServiceCreate";
import OrderServiceList from "./orderservices/OrderServicesList";
import OrderServiceEdit from "./orderservices/OrderServiceEdit";
import OrderServiceDelete from "./orderservices/OrderServiceDelete";
import OrderServiceShow from "./orderservices/OrderServiceShow";

import CompanyUserCreate from "./companyusers/CompanyUserCreate";
import CompanyUserList from "./companyusers/CompanyUserList";
import CompanyUserEdit from "./companyusers/CompanyUserEdit";
import CompanyUserDelete from "./companyusers/CompanyUserDelete";
import CompanyUserShow from "./companyusers/CompanyUserShow";

class App extends React.Component {
  render() {
    return (
      <Router history={history}>
        <Header />
        <Switch>
          <Route exact path="/" component={Home} />
          <Route path="/logout" component={Logout} />
          {/*General*/}
          <Route path="/dashboard" component={DashboardShow} />
          <Route path="/account/show" component={AccountShow} />
          {/*User*/}
          <Route path="/user/new" component={UserCreate} />
          <Route path="/user/list" component={UserList} />
          <Route path="/user/edit/:id" component={UserEdit} />
          <Route path="/user/delete/:id" component={UserDelete} />
          <Route path="/user/show/:id" component={UserShow} />
          {/*Conta*/}
          <Route path="/conta/new" component={ContaCreate} />
          <Route path="/conta/list" component={ContaList} />
          <Route path="/conta/edit/:id" component={ContaEdit} />
          <Route path="/conta/delete/:id" component={ContaDelete} />
          <Route path="/conta/show/:id" component={ContaShow} />
          {/*Company*/}
          <Route path="/company/new" component={CompanyCreate} />
          <Route path="/company/list" component={CompanyList} />
          <Route path="/company/edit/:id" component={CompanyEdit} />
          <Route path="/company/delete/:id" component={CompanyDelete} />
          <Route path="/company/show/:id" component={CompanyShow} />
          {/*Contract*/}
          <Route path="/contract/new" component={ContractCreate} />
          <Route path="/contract/list" component={ContractList} />
          <Route path="/contract/edit/:id" component={ContractEdit} />
          <Route path="/contract/delete/:id" component={ContractDelete} />
          <Route path="/contract/show/:id" component={ContractShow} />
          {/*Report*/}
          <Route path="/report/new" component={ReportCreate} />
          <Route path="/report/list" component={ReportList} />
          <Route path="/report/edit/:id" component={ReportEdit} />
          <Route path="/report/delete/:id" component={ReportDelete} />
          <Route path="/report/show/:id" component={ReportShow} />
          {/*Order Service*/}
          <Route path="/order_service/new" component={OrderServiceCreate} />
          <Route path="/order_service/list" component={OrderServiceList} />
          <Route path="/order_service/edit/:id" component={OrderServiceEdit} />
          <Route
            path="/order_service/delete/:id"
            component={OrderServiceDelete}
          />
          <Route path="/order_service/show/:id" component={OrderServiceShow} />
          {/*Company Users*/}
          <Route path="/company_user/new" component={CompanyUserCreate} />
          <Route path="/company_user/list" component={CompanyUserList} />
          <Route path="/company_user/edit/:id" component={CompanyUserEdit} />
          <Route
            path="/company_user/delete/:id"
            component={CompanyUserDelete}
          />
          <Route path="/company_user/show/:id" component={CompanyUserShow} />
        </Switch>
      </Router>
    );
  }
}

const mapStateToProps = (state) => ({
  ...state.auth,
});

export default connect(mapStateToProps, { userLogout })(App);
