import React from "react";
import axios from "axios";
import requireAuth from "../auth/requireAuth";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { Field, reduxForm } from "redux-form";
import { fetchSegmentTypes } from "../../actions";
import { fetchContas } from "../../actions";
import { createTextMask } from "redux-form-input-masks";
import Select from "react-select";

import { cnpjValidate } from "../../util/cnpj-cpf-validate/cnpjValidate";
import _ from "lodash";

class CompanyForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      term: "",
      searchedCep: {
        bairro: "",
        cep: "",
        ddd: "",
        gia: "",
        ibge: "",
        localidade: "",
        logradouro: "",
        siafi: "",
        uf: "",
      },
    };
  }

  componentDidMount() {
    this.props.change("type", `/api/company_types/2`);
    this.props.fetchSegmentTypes();
    this.props.fetchContas();
  }

  renderError({ touched, error }) {
    if (touched && error) {
      return (
        <div className={"ui tiny error message"}>
          <div className={"header"}>{error}</div>
        </div>
      );
    }
  }

  renderInputCNPJ = ({ input, label, meta, disabled }) => {
    const className = `field ${meta.error && meta.touched ? "error" : ""}`;

    return (
      <div className={className}>
        <label>{label}</label>
        <input {...input} autoComplete={"off"} disabled={disabled} />

        {this.renderError(meta)}
      </div>
    );
  };

  renderInput = ({ input, label, meta }) => {
    const className = `field ${meta.error && meta.touched ? "error" : ""}`;
    return (
      <div className={className}>
        <label>{label}</label>
        <input {...input} autoComplete={"off"} />
        {this.renderError(meta)}
      </div>
    );
  };

  renderOptionSegmentType() {
    return this.props.segment_types.map((segment) => ({
      value: segment.id,
      label: segment.name,
    }));
  }

  renderOptionSegmentInitialValue() {
    if (this.props.initialValues) {
      const { segment } = this.props.initialValues;
      return {
        value: segment.id,
        label: segment.name,
      };
    }
  }

  renderSelectSegmentType = ({ input, value, label, meta }) => {
    const className = `field ${meta.error && meta.touched ? "error" : ""}`;

    const editValueSegment = (() => {
      if (this.props.initialValues) {
        // console.log(this.props.initialValues.segment["@id"]);
        // console.log(input.value.id);
        if (input.value.id !== undefined) {
          // console.log(input.value.id);
          // console.log("IF");
          input.onChange(this.props.initialValues.segment["@id"]);
          return (value) => input.onChange(`/api/segments/${value.value}`);
        } else {
          // console.log(input.value.id);
          // console.log("ELSE");
          return (value) => input.onChange(`/api/segments/${value.value}`);
        }
      } else {
        return (value) => input.onChange(`/api/segments/${value.value}`);
      }
    })();

    return (
      <div className={className}>
        <label>{label}</label>
        <Select
          {...input}
          options={this.renderOptionSegmentType()}
          value={value}
          defaultValue={this.renderOptionSegmentInitialValue()}
          placeholder={"Selecione o segmento..."}
          onChange={editValueSegment}
          onBlur={() => input.onBlur(input.value)}
          // onBlur={
          //   this.props.initialValues
          //     ? () => input.onBlur(idListSegment)
          //     : () => input.onBlur(input.value)
          // }
        />
        {this.renderError(meta)}
      </div>
    );
  };

  renderOptionConta() {
    return this.props.contas.map((conta) => ({
      value: conta.id,
      label: conta.fantasyName,
    }));
  }

  renderOptionContaInitialValue() {
    if (this.props.initialValues) {
      // console.log(this.props.initialValues);
      const { conta } = this.props.initialValues;
      return {
        value: conta.id,
        label: conta.fantasyName,
      };
    }
  }

  renderSelectConta = ({ input, value, label, meta }) => {
    const className = `field ${meta.error && meta.touched ? "error" : ""}`;

    const editValueConta = (() => {
      if (this.props.initialValues) {
        // console.log(this.props.initialValues.segment["@id"]);
        // console.log(input.value.id);
        if (input.value.id !== undefined) {
          // console.log(input.value.id);
          // console.log("IF");
          input.onChange(this.props.initialValues.conta["@id"]);
          return (value) => input.onChange(`/api/contas/${value.value}`);
        } else {
          // console.log(input.value.id);
          // console.log("ELSE");
          return (value) => input.onChange(`/api/contas/${value.value}`);
        }
      } else {
        return (value) => input.onChange(`/api/contas/${value.value}`);
      }
    })();

    return (
      <div className={className}>
        <label>{label}</label>
        <Select
          {...input}
          options={this.renderOptionConta()}
          value={value}
          defaultValue={this.renderOptionContaInitialValue()}
          placeholder={"Selecione o Grupo..."}
          onChange={editValueConta}
          onBlur={() => input.onBlur(input.value)}
        />
        {this.renderError(meta)}
      </div>
    );
  };

  renderInputTextArea = ({ input, label, meta }) => {
    const className = `field ${meta.error && meta.touched ? "error" : ""}`;
    return (
      <div className={className}>
        <label>{label}</label>
        <textarea rows="4" {...input} autoComplete="off" />
        {this.renderError(meta)}
      </div>
    );
  };

  renderSearchCep = ({ input, label, meta, value }) => {
    const className = `field ${meta.error && meta.touched ? "error" : ""}`;

    //Busca term que é o cep
    const cep = this.state.term;

    if (cep) {
      axios
        .get(`${process.env.REACT_APP_API_BUSCACEP}/${cep}/json/`)
        .then((response) => this.setState({ searchedCep: response.data }))
        .catch((err) => {
          console.error(
            "Ops! Acho que busca cep não funcionou, digite na mão." + err
          );
        });
    }

    const initZip = (() => {
      if (this.props.initialValues) {
        return this.props.initialValues.address.zip;
      } else {
        return value;
      }
    })();

    return (
      <div className={className}>
        <label>{label}</label>
        <div className="ui tiny right action input">
          <input
            {...input}
            type="text"
            value={initZip}
            onChange={(e) => this.setState({ term: e.target.value })}
            maxLength="8"
            autoComplete={"off"}
          />
          <button className="ui tiny yellow labeled icon button" type="button">
            CEP
            <i className="search icon" />
          </button>
          {/*<div className="ui mini yellow label">*/}
          {/*  <i className="search icon" />*/}
          {/*  CEP*/}
          {/*</div>*/}
        </div>
        <p style={{ color: "red", marginTop: "-1px", fontSize: "12px" }}>
          “Preencher apenas números”.
        </p>
        {this.renderError(meta)}
      </div>
    );
  };

  renderStreet = ({ input, label, meta, value }) => {
    // console.log(this.state);
    const className = `field ${meta.error && meta.touched ? "error" : ""}`;
    // console.log("street" + this.state.searchedCep.logradouro);
    // console.log(value);
    const initLogradouro = (() => {
      if (this.props.initialValues) {
        return this.props.initialValues.address.street;
      } else {
        // input.onChange(this.state.searchedCep.logradouro);
        return this.state.searchedCep.logradouro
          ? this.state.searchedCep.logradouro
          : value;
      }
    })();

    return (
      <div className={className}>
        <label>{label}</label>
        <input
          {...input}
          value={initLogradouro}
          // value={value}
          // onChange={editValueCompany}
          // onChange={initLogradouro}
          autoComplete={"off"}
          onBlur={() => input.onBlur(input.value)}
        />
        {this.renderError(meta)}
      </div>
    );
  };

  renderNeighbourhood = ({ input, label, meta, value }) => {
    const className = `field ${meta.error && meta.touched ? "error" : ""}`;

    const initNeighbourhood = (() => {
      if (this.props.initialValues) {
        return this.props.initialValues.address.neighbourhood;
      } else {
        return this.state.searchedCep.bairro
          ? this.state.searchedCep.bairro
          : value;
      }
    })();

    return (
      <div className={className}>
        <label>{label}</label>
        <input
          {...input}
          value={initNeighbourhood}
          // value={
          //   this.state.searchedCep.bairro
          //     ? this.state.searchedCep.bairro
          //     : value
          // }
          onChange={
            this.state.searchedCep.bairro
              ? input.onChange(this.state.searchedCep.bairro)
              : value
          }
          autoComplete={"off"}
        />
        {this.renderError(meta)}
      </div>
    );
  };

  renderState = ({ input, label, meta, value }) => {
    const className = `field ${meta.error && meta.touched ? "error" : ""}`;

    const initState = (() => {
      if (this.props.initialValues) {
        return this.props.initialValues.address.state;
      } else {
        return this.state.searchedCep.uf ? this.state.searchedCep.uf : value;
      }
    })();

    return (
      <div className={className}>
        <label>{label}</label>
        <input
          {...input}
          value={initState}
          // value={this.state.searchedCep.uf ? this.state.searchedCep.uf : value}
          onChange={
            this.state.searchedCep.uf
              ? input.onChange(this.state.searchedCep.uf)
              : value
          }
          autoComplete={"off"}
        />
        {this.renderError(meta)}
      </div>
    );
  };

  renderCity = ({ input, label, meta, value }) => {
    const className = `field ${meta.error && meta.touched ? "error" : ""}`;

    const initCity = (() => {
      if (this.props.initialValues) {
        return this.props.initialValues.address.city;
      } else {
        return this.state.searchedCep.localidade
          ? this.state.searchedCep.localidade
          : value;
      }
    })();

    return (
      <div className={className}>
        <label>{label}</label>
        <input
          {...input}
          value={initCity}
          // value={
          //   this.state.searchedCep.localidade
          //     ? this.state.searchedCep.localidade
          //     : value
          // }
          onChange={
            this.state.searchedCep.localidade
              ? input.onChange(this.state.searchedCep.localidade)
              : value
          }
          autoComplete={"off"}
        />
        {this.renderError(meta)}
      </div>
    );
  };

  onSubmit = (formValues) => {
    this.props.onSubmit(formValues);
  };

  render() {
    // console.log(this.props);
    const { submitting } = this.props;
    return (
      <div>
        <form
          name={"company"}
          className={"ui tiny form error"}
          onSubmit={this.props.handleSubmit(this.onSubmit)}
        >
          <div className={"ui equal width fields"}>
            <Field
              type={"text"}
              name={"document"}
              component={this.renderInputCNPJ}
              label={"CNPJ"}
              disabled={this.props.disable}
              {...cnpjMask}
            />
            <Field
              type={"text"}
              name={"conta"}
              component={this.renderSelectConta}
              label={"Grupo Empresarial"}
            />
            <Field
              type={"text"}
              name={"segment"}
              component={this.renderSelectSegmentType}
              label={"Segmento"}
            />
          </div>
          <div className={"ui equal width fields"}>
            <Field
              type={"text"}
              name={"socialReason"}
              component={this.renderInput}
              label={"Razão Social"}
            />
            <Field
              type={"text"}
              name={"fantasyName"}
              component={this.renderInput}
              label={"Nome Fantasia"}
            />
          </div>
          <div className={"ui equal width fields"}>
            <Field
              type={"text"}
              name={"initials"}
              component={this.renderInput}
              label={"Sigla"}
            />
            <Field
              type={"text"}
              name={"phone"}
              component={this.renderInput}
              label={"Telefone"}
              autoComplete="off"
              {...companyTelephoneMask}
            />
          </div>
          <div className={"ui equal width fields"}>
            <Field
              type={"text"}
              name={"stateRegistration"}
              component={this.renderInput}
              label={"Inscrição Estadual"}
            />
            <Field
              type={"text"}
              name={"municipalRegistration"}
              component={this.renderInput}
              label={"Inscrição Municipal"}
            />
          </div>
          <div className={"ui grid"}>
            <div className={"row"}>
              <div
                className={
                  "ui twelve wide right aligned column bottom aligned content"
                }
              >
                <h5>Endereço</h5>
              </div>
              <div
                className={
                  "ui four wide right aligned column bottom aligned content"
                }
              >
                <Link
                  className={"ui mini icon right labeled button"}
                  to={"/company/list"}
                >
                  <i aria-hidden="true" className="reply icon" /> Voltar
                </Link>
              </div>
            </div>
          </div>
          <div className={"ui divider"} />

          <div className={"ui equal width fields"}>
            <Field
              name={"address.zip"}
              component={this.renderSearchCep}
              label={"CEP"}
            />
            <Field
              type={"text"}
              name={"address.street"}
              component={this.renderStreet}
              label={"Logradouro"}
            />
          </div>
          <div className={"ui fields"}>
            <div className="ui four wide field">
              <Field
                type={"text"}
                name={"address.number"}
                component={this.renderInput}
                label={"Numero"}
              />
            </div>
            <div className="ui twelve wide field">
              <Field
                type={"text"}
                name={"address.compliment"}
                component={this.renderInput}
                label={"Complemento"}
              />
            </div>
          </div>
          <div className={"ui equal width fields"}>
            <Field
              type={"text"}
              name={"address.neighbourhood"}
              component={this.renderNeighbourhood}
              label={"Bairro"}
            />
            <Field
              type={"text"}
              name={"address.state"}
              component={this.renderState}
              label={"Estado"}
            />
            <Field
              type={"text"}
              name={"address.city"}
              component={this.renderCity}
              label={"Cidade"}
            />
          </div>
          <Field
            type={"text"}
            name={"observations"}
            component={this.renderInputTextArea}
            label={"Observações"}
          />
          <div className={"ui container right aligned"}>
            <div className={"ui buttons"}>
              <Link className={"ui red button"} to={"/company/list"}>
                Cancelar
              </Link>
              <div className={"or"} data-text={"ou"} />
              <button
                type="submit"
                className={"ui primary button"}
                disabled={submitting}
              >
                Salvar
              </button>
            </div>
          </div>
          <br />
          <br />
        </form>
      </div>
    );
  }
}

const cnpjMask = createTextMask({
  pattern: "99.999.999/9999-99",
  stripMask: false,
});

const companyTelephoneMask = createTextMask({
  pattern: "(99) 9999-9999",
  stripMask: false,
});

// const cepMask = createTextMask({
//   pattern: "99999-999",
//   stripMask: false,
// });

const validate = (formValues, initialValues) => {
  const errors = {};
  // console.log("LISTA DE CNPJ", initialValues.chekDocumentT);

  if (!formValues.document) {
    // console.log("DENTRO", initialValues.listDocumentValue);
    errors.document = "Campo Obrigatório informe o CNPJ";
  } else if (!cnpjValidate(formValues.document)) {
    errors.document = "CNPJ INVÁLIDO verifique a digitação";
  } else {
    if (typeof initialValues.initialValues === "undefined") {
      if (initialValues.listDocumentValue.indexOf(formValues.document) !== -1) {
        errors.document = "CNPJ JÁ Existe";
      }
    }
  }

  if (!formValues.segment) {
    errors.segment = "Campo Obrigatório informe o Segmento";
  }

  if (!formValues.socialReason) {
    errors.socialReason = "Campo Obrigatório infome a Razão Social";
  }

  // if (!formValues.address.state) {
  //   errors.address.state = "Campo Obrigatório infome a Razão Social";
  // }

  return errors;
};

const formWrapped = reduxForm({
  form: "companyForm",
  enableReinitialize: true,
  validate,
})(CompanyForm);

const mapStateToProps = (state) => {
  return {
    segment_types: Object.values(state.segment_types),
    contas: _.orderBy(Object.values(state.contas), "fantasyName", "asc"),
  };
};

export default requireAuth(
  connect(mapStateToProps, {
    fetchSegmentTypes,
    fetchContas,
  })(formWrapped)
);
