import _ from "lodash";
import {
  SELECTED_ORDER_SERVICES,
  CREATE_ORDER_SERVICES,
  FETCH_ORDER_SERVICESS,
  FETCH_ORDER_SERVICES,
  EDIT_ORDER_SERVICES,
  DELETE_ORDER_SERVICES,
} from "../actions/types";

// eslint-disable-next-line import/no-anonymous-default-export
export default (state = {}, action) => {
  switch (action.type) {
    case SELECTED_ORDER_SERVICES:
      return { ...state, [action.payload.id]: action.payload };
    case FETCH_ORDER_SERVICESS:
      return { ...state, ..._.mapKeys(action.payload["hydra:member"], "id") };
    case FETCH_ORDER_SERVICES:
      return { ...state, [action.payload.id]: action.payload };
    case CREATE_ORDER_SERVICES:
      return { ...state, [action.payload.id]: action.payload };
    case EDIT_ORDER_SERVICES:
      return { ...state, [action.payload.id]: action.payload };
    case DELETE_ORDER_SERVICES:
      return _.omit(state, action.payload);

    default:
      return state;
  }
};
