import orderServices from "../apis/agentApi";
import {
  SELECTED_ORDER_SERVICES,
  CREATE_ORDER_SERVICES,
  FETCH_ORDER_SERVICESS,
  FETCH_ORDER_SERVICES,
  EDIT_ORDER_SERVICES,
  DELETE_ORDER_SERVICES,
} from "./types";
import history from "../history";

// Start Report
export const selectedOrderService = (orderService) => {
  return {
    type: SELECTED_ORDER_SERVICES,
    payload: orderService,
  };
};

export const createOrderService = (formValues) => async (dispatch) => {
  const response = await orderServices.post("/api/order_services", formValues);

  dispatch({ type: CREATE_ORDER_SERVICES, payload: response.data });
  history.push("/order_service/list");
};

export const fetchOrderServices = () => async (dispatch) => {
  const response = await orderServices.get("/api/order_services");

  dispatch({ type: FETCH_ORDER_SERVICESS, payload: response.data });
};

export const fetchOrderService = (id) => async (dispatch) => {
  const response = await orderServices.get(`/api/order_services/${id}`);

  dispatch({ type: FETCH_ORDER_SERVICES, payload: response.data });
};

export const editOrderService = (id, formValues) => async (dispatch) => {
  // Customize axios instance for patch
  let axiosConfig = {
    headers: {
      "Content-Type": "application/merge-patch+json",
    },
  };
  // End //

  const response = await orderServices.patch(
    `/api/order_services/${id}`,
    formValues,
    axiosConfig
  );

  dispatch({ type: EDIT_ORDER_SERVICES, payload: response.data });
  history.push("/order_service/list");
};

export const deleteReport = (id) => async (dispatch) => {
  await orderServices.delete(`/api/order_services/${id}`);

  dispatch({ type: DELETE_ORDER_SERVICES, payload: id });
};
