import _ from "lodash";
import React from "react";
import requireAuth from "../auth/requireAuth";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { Field, reduxForm } from "redux-form";
import Select from "react-select";
import fileSize from "filesize";
import { Checkbox, Divider } from "semantic-ui-react";
import DropboxUpload from "../../util/dropzone/DropboxUpload";
import DropboxFileList from "../../util/dropzone/DropboxFileList";

import { PDFObject } from "react-pdfobject";

import agentApi from "../../apis/agentApi";

import DayPickerInput from "react-day-picker/DayPickerInput";

import "react-day-picker/lib/style.css";

import MomentLocaleUtils, {
  formatDate,
  parseDate,
} from "react-day-picker/moment";

import "moment/locale/pt-br";

import {
  fetchCompanies,
  fetchServiceTypes,
  fetchEquipmentTypes,
  fetchOrderServices,
  fetchUsers,
  fetchReports,
} from "../../actions";

// Call from env
const apiUrl = process.env.REACT_APP_UPLOAD_URL;

const styles3 = {
  border: '1px solid rgba(0, 0, 0, 0.8)',       
};

class ReportForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      value: [],
      label: [],
      selectedDay: undefined,
      file: null,
      uploadedFiles: [],
      opts: [],
      checkedTheFirstReport: false,
      showOrderService: true,
      showFirstReport: true,
      triggerClearInsatisfactory: false,
      companyChosenForOrderServiceAndOldReport: "",
      oldReportLists: [],
      orderServiceChosenForCleanDropDown: "",
      checkedIsDelete: false,
    };
  }

  componentDidMount() {
    this.props.fetchCompanies();
    this.props.fetchServiceTypes();
    this.props.fetchEquipmentTypes();
    this.props.fetchOrderServices();
    this.props.fetchUsers();
    this.props.fetchReports();
    if (this.props.initialValues) {
      //TRAZ OS ANTERIORES NA EDICAO
      if (this.state.companyChosenForOrderServiceAndOldReport === "") {
        let reportId = this.props.initialValues.id;
        let resList = [];
        agentApi
          .get(
            `/api/reports/unsatisfactory/list/${this.props.initialValues.company.id}`
          )
          .then((res) => {
            //SE O PROPRIO EH INSATISF NAO PODE VIR NA LISTAGEM
            if (this.props.initialValues.isNotSatisfactory) {
              for (var i = 0; i < res.data.length; i++) {
                if (res.data[i].id != reportId) {
                  resList.push(res.data[i]);
                }
              }
              this.setState({ oldReportLists: resList });
            } else {
              //SENAO VEM TODOS
              this.setState({ oldReportLists: res.data });
            }
          })
          .catch(function (error) {
            console.log(error);
          });
      }
      //SETA O DISPLAY DO CHECKBOX ANTERIORES DE ACORDO COM SATISFATORIO
      this.setFirstReportState(this.props.initialValues.isNotSatisfactory);
    }
  }

  getSnapshotBeforeUpdate(prevProps, prevState) {
    if (prevState.oldReportLists !== this.state.oldReportLists) {
      const list = this.state.oldReportLists;
      // console.log(list);
      this.setState({
        // companyChosenForOrderServiceAndOldReport: value.value,
        orderServiceChosenForCleanDropDown: null,
      });
    }

    return null;
  }

  componentDidUpdate(previousProps, prevState) {
    if (this.state.companyChosenForOrderServiceAndOldReport === "") {
      return null;
    } else {
      if (
        this.state.companyChosenForOrderServiceAndOldReport !==
        prevState.companyChosenForOrderServiceAndOldReport
      ) {
        agentApi
          .get(
            `/api/reports/unsatisfactory/list/${this.state.companyChosenForOrderServiceAndOldReport}`
          )
          .then((res) => {
            this.setState({ oldReportLists: res.data });
          })
          .catch(function (error) {
            console.log(error);
          });

        if (!this.props.initialValues) {
          //this.state.showOrderService = !this.state.showOrderService;
          this.setState({ showOrderService: !this.state.showOrderService });

          if (
            this.state.showFirstReport === false &&
            this.state.triggerClearInsatisfactory
          ) {
            //  console.log('false - 134');
            this.setState({ showFirstReport: !this.state.showFirstReport });
            this.setState({ triggerClearInsatisfactory: false });
          }
          //console.log('fos' + this.state.showOrderService);
        }
      }
    }
  }

  filterOrderService(idhidra) {
    const newOrders = [];
    const finalOrders = [];

    _.forEach(this.props.orderServices, function (value, key) {
      if (value.company["@id"] == idhidra) {
        newOrders.push(value);
      }
    });

    // console.log("ORDENS", newOrders);

    this.state.opts = _.intersectionWith(
      this.props.orderServices,
      newOrders,
      _.isEqual
    );

    var oReports = this.props.reports;
    var initValues = this.props.initialValues;

    _.forEach(this.state.opts, function (value, key) {
      // 1

      var eachopts = value;
      var go = true;

      _.forEach(oReports, function (value, key) {
        // 2
        if (typeof initValues === "undefined") {
          if (value.company["@id"] == eachopts.company["@id"]) {
            if (value.orderService) {
              if (value.orderService.id == eachopts.id) {
                go = false;
                return false;
              }
            }
          }
        } else {
          if (value.company["@id"] == eachopts.company["@id"]) {
            if (value.orderService) {
              if (initValues.orderService) {
                if (
                  value.orderService.id == eachopts.id &&
                  initValues.orderService.id != eachopts.id
                ) {
                  go = false;
                  return false;
                }
              } else {
                if (value.orderService.id == eachopts.id) {
                  go = false;
                  return false;
                }
              }
            }
          }
        }
      }); //2 fim

      if (go) {
        finalOrders.push(eachopts);
      }
    }); //1 fim
    this.state.opts = finalOrders;
  }

  renderError({ error, touched }) {
    if (touched && error) {
      return (
        <div className={"ui tiny error message"}>
          <div className={"header"}>{error}</div>
        </div>
      );
    }
  }

  renderInput = ({ input, label, meta }) => {
    const className = `field ${meta.error && meta.touched ? "error" : ""}`;
    return (
      <div className={className}>
        <label>{label}</label>
        <input {...input} autoComplete="off" />
        {this.renderError(meta)}
      </div>
    );
  };

  renderOptionCompany() {
    //console.log(this.state);
    return this.props.companies.map((company) => ({
      value: company.id,
      label: company.fantasyName,
    }));
  }

  renderOptionCompanyInitialValue() {
    if (this.props.initialValues) {
      const { company } = this.props.initialValues;
      return {
        value: company.id,
        label: company.fantasyName,
      };
    }
  }

  renderSelectCompany = ({ input, value, label, meta, disabled }) => {
    const className = `field ${meta.error && meta.touched ? "error" : ""}`;
    const idhidra = input.value;

    const editValueCompany = (() => {
      if (this.props.initialValues) {
        // ++++ If Come from Edit
        // console.log("COMPANY TRAZ INPUTVALUE", input.value);
        if (input.value.id !== undefined) {
          // console.log("IF INPUT VALUE COMPANY", input.value.id);
          // console.log("IF COMPANY");
          input.onChange(this.props.initialValues.company["@id"]);
          return (value) => input.onChange(`/api/companies/${value.value}`);
        } else {
          // console.log("ELSE INPUT VALUE COMPANY", input.value.id);
          // console.log("ELSE COMPANY");
          this.filterOrderService(idhidra);
          return (value) => input.onChange(`/api/companies/${value.value}`);
        }
      } else if (!this.props.initialValues) {
        // ++++ If come from Create
        // console.log("STATE", this.state);

        this.filterOrderService(idhidra);
        return (value) => {
          input.onChange(`/api/companies/${value.value}`);

          this.setState({
            companyChosenForOrderServiceAndOldReport: value.value,
            orderServiceChosenForCleanDropDown: null,
            showOrderService: !this.state.showOrderService,
          });

          if (
            this.state.showFirstReport === true &&
            this.state.checkedTheFirstReport === true
          ) {
            //console.log('true - 304');
            this.setState({ showFirstReport: !this.state.showFirstReport });
            this.setState({
              checkedTheFirstReport: !this.state.showFirstReport,
            });
            this.setState({ triggerClearInsatisfactory: true });
          }
          //console.log('evc' + this.state.showOrderService);
        };
      }
    })();

    return (
      <div className={className}>
        <label>{label}</label>
        <Select
          {...input}
          isDisabled={disabled}
          options={this.renderOptionCompany()}
          value={value}
          defaultValue={this.renderOptionCompanyInitialValue()}
          placeholder={"Selecione a empresa..."}
          companyOlderReport={value}
          onChange={editValueCompany}
          onBlur={() => input.onBlur(input.value)}
        />
        {this.renderError(meta)}
      </div>
    );
  };

  renderOptionOrderService() {
    // console.log("OPTS", this.state.opts);
    // console.log("STATUS DROP", this.state.orderServiceChosenForCleanDropDown);

    return this.state.opts.map((orderService) => ({
      value: orderService.id,
      label: orderService.title,
    }));
  }

  renderOptionOrderServiceInitialValue() {
    if (this.props.initialValues && this.props.initialValues.orderService) {
      const { orderService } = this.props.initialValues;
      return {
        value: orderService.id,
        label: orderService.title,
      };
    }
  }

  renderSelectOrderService = ({ input, value, label, meta }) => {
    const className = `field ${meta.error && meta.touched ? "error" : ""}`;

    const editValueOrderService = (() => {
      if (this.props.initialValues) {
        if (input.value.id !== undefined) {
          input.onChange(this.props.initialValues.orderService["@id"]);
          return (value) =>
            input.onChange(`/api/order_services/${value.value}`);
        } else {
          return (value) =>
            input.onChange(`/api/order_services/${value.value}`);
        }
      } else {
        return (value) => {
          input.onChange(`/api/order_services/${value.value}`);
          // console.log(value);
          // this.setState({
          //   companyChosenForOrderServiceAndOldReport: null,
          //   orderServiceChosenForCleanDropDown: value.value,
          // });
        };
      }
    })();

    return (
      <div className={className}>
        <label>{label}</label>
        <Select
          {...input}
          options={this.renderOptionOrderService()}
          value={value}
          defaultValue={this.renderOptionOrderServiceInitialValue()}
          placeholder={"Selecione a ordem de serviço..."}
          onChange={editValueOrderService}
          // onClick={this.handleClick2}
          onBlur={() => input.onBlur(input.value)}
        />
        {this.renderError(meta)}
      </div>
    );
  };

  renderOptionService() {
    const { services } = this.props;
    return services.map((service) => ({
      value: service.value,
      label: service.name,
    }));
  }

  renderOptionServiceInitialValue() {
    if (this.props.initialValues) {

      var newArray = [];
      var isObject = 0;

      if(!_.isArray(this.props.initialValues.services)){
        isObject = 1;
        var j = 0;
        for (var i in this.props.initialValues.services){
          if(this.props.initialValues.services[i] != ''){
            newArray[j] = this.props.initialValues.services[i];
            j++;
          }
        }
      }

      if(isObject){
        this.props.initialValues.services = newArray;
      }

      const { services } = this.props.initialValues;
      return services.map((service) => ({
        value: service.value,
        label: service.name,
      }));
    }
  }

  renderSelectService = ({ input, value, label, meta }) => {
    const className = `field ${meta.error && meta.touched ? "error" : ""}`;

    const editValueService = (() => {
      if (this.props.initialValues) {
        if (_.isObject(input.value[0])) {        
          const { services } = this.props.initialValues;
          input.onChange(
            services.map((service) => {
              return service["@id"];
            })
          );
          return (value) => input.onChange([`/api/services/${value.value}`]);
        } else {
          // console.log(input.value.id);
          // console.log("ELSE");
          return (value) => input.onChange([`/api/services/${value.value}`]);
        }
      } else {
        return (value) => input.onChange([`/api/services/${value.value}`]);
      }
    })();

    return (
      <div className={className}>
        <label>{label}</label>
        <Select
          {...input}
          options={this.renderOptionService()}
          value={value}
          defaultValue={this.renderOptionServiceInitialValue()}
          placeholder={"Selecione o serviço..."}
          onChange={editValueService}
          onBlur={() => input.onBlur(input.value)}
        />
        {this.renderError(meta)}
      </div>
    );
  };

  renderOptionEquipment() {
    const { equipments } = this.props;
    return equipments.map((equipment) => ({
      value: equipment.value,
      label: equipment.name,
    }));
  }

  renderOptionEquipmentInitialValue() {
    if (this.props.initialValues) {

      var newArray = [];
      var isObject = 0;

      if(!_.isArray(this.props.initialValues.equipments)){
        isObject = 1;
        var j = 0;
        for (var i in this.props.initialValues.equipments){
          if(this.props.initialValues.equipments[i] != ''){
            newArray[j] = this.props.initialValues.equipments[i];
            j++;
          }
        }
      }

      if(isObject){
        this.props.initialValues.equipments = newArray;
      }

      const { equipments } = this.props.initialValues;
      return equipments.map((equipment) => ({
        value: equipment.value,
        label: equipment.name,
      }));
    }
  }

  renderSelectEquipment = ({ input, value, label, meta }) => {
    const className = `field ${meta.error && meta.touched ? "error" : ""}`;

    const editValueEquipment = (() => {
      if (this.props.initialValues) {
        if (_.isObject(input.value[0])) {
          const { equipments } = this.props.initialValues;
          input.onChange(
            equipments.map((equipment) => {
              return equipment["@id"];
            })
          );
          return (value) => input.onChange([`/api/equipment/${value.value}`]);
        } else {
          // console.log(input.value.id);
          // console.log("ELSE");
          return (value) => input.onChange([`/api/equipment/${value.value}`]);
        }
      } else {
        return (value) => input.onChange([`/api/equipment/${value.value}`]);
      }
    })();

    return (
      <div className={className}>
        <label>{label}</label>
        <Select
          {...input}
          options={this.renderOptionEquipment()}
          value={value}
          defaultValue={this.renderOptionEquipmentInitialValue()}
          placeholder={"Selecione o equipamento..."}
          onChange={editValueEquipment}
          onBlur={() => input.onBlur(input.value)}
        />
        {this.renderError(meta)}
      </div>
    );
  };

  renderInputStartDate = ({ input, label, meta, type }) => {
    const className = `field ${meta.error && meta.touched ? "error" : ""}`;

    const formatValue = (() => {
      if (this.props.initialValues) {
        const { startDate } = this.props.initialValues;
        return formatDate(startDate, "L", "pt-br");
      }
    })();

    return (
      <div className={className}>
        <label>{label}</label>
        <DayPickerInput
          {...input}
          type={type}
          onDayChange={input.onChange}
          selectedDay={this.state.selectedDay}
          formatDate={formatDate}
          parseDate={parseDate}
          value={formatValue}
          format="L"
          placeholder={`${formatDate(new Date(), "L", "pt-br")}`}
          dayPickerProps={{
            locale: "pt-br",
            localeUtils: MomentLocaleUtils,
          }}
        />
        {this.renderError(meta)}
      </div>
    );
  };

  renderInputEndDate = ({ input, label, meta, type }) => {
    const className = `field ${meta.error && meta.touched ? "error" : ""}`;

    const formatValue = (() => {
      if (this.props.initialValues) {
        const { endDate } = this.props.initialValues;
        return formatDate(endDate, "L", "pt-br");
      }
    })();

    return (
      <div className={className}>
        <label>{label}</label>
        <DayPickerInput
          {...input}
          type={type}
          onDayChange={input.onChange}
          selectedDay={this.state.selectedDay}
          formatDate={formatDate}
          parseDate={parseDate}
          value={formatValue}
          format="L"
          placeholder={`${formatDate(new Date(), "L", "pt-br")}`}
          dayPickerProps={{
            locale: "pt-br",
            localeUtils: MomentLocaleUtils,
          }}
        />
        {this.renderError(meta)}
      </div>
    );
  };

  setFirstReportState(is_not_satisfactory) {
    if (is_not_satisfactory) {
      if (this.props.initialValues.oldReport) {
        this.setState({
          showFirstReport: true,
          checkedTheFirstReport: true,
        });
      } else {
        this.setState({
          showFirstReport: false,
        });
      }
    } else {
      this.setState({
        showFirstReport: true,
      });

      if (this.props.initialValues.oldReport) {
        this.setState({
          checkedTheFirstReport: true,
        });
      }
    }
  }

  //MANTER FUNCAO
  /*  hasCorrection(){
    let reportId = this.props.initialValues.id;
    let oReportsCorrection = this.props.reports;
    let hasIt = 0;
    for(var i=0; i < oReportsCorrection.length; i++){
      if(oReportsCorrection[i].oldReport){
        if(oReportsCorrection[i].oldReport.id == reportId){ hasIt++; break;}
      }
    }
    return hasIt < 1 ? false : true;
  }; */

  renderCheckBoxSatisfactory = ({ input, label, meta, value }) => {
    const className = `field ${meta.error && meta.touched ? "error" : ""}`;

    const isChecked = (() => {
      if (this.props.initialValues) {
        if (this.props.initialValues.isNotSatisfactory === true) {
          return true;
        } else {
          return false;
        }
      } else {
        return false;
      }
    })();

    this.handleChange = (e) => {
      /*    if(this.hasCorrection()){
        alert("Este laudo não pode ter seu estado de 'Insatisfatório' alterado pois já possui uma correção");
        e.preventDefault();
        e.stopPropagation(true);
        //isChecked.checked = true;
        return;
      }else{ */
      if (!isChecked) {
        if (this.props.initialValues) {
          // with initialValues
          if (this.props.initialValues.oldReport) {
            // with oldReport
            //  console.log(this.state.showFirstReport);
            this.setState({
              showFirstReport: true,
              checkedTheFirstReport: true,
            });
          } else {
            // no oldReport
            this.setState({
              showFirstReport: !this.state.showFirstReport,
              checkedTheFirstReport: false,
            });
          }
        } else {
          // no initialValues
          this.setState({
            showFirstReport: !this.state.showFirstReport,
            checkedTheFirstReport: false,
          });
        }
      } else {
        if (this.props.initialValues.oldReport) {
          // with oldReport
          //  console.log(this.state.showFirstReport);
          this.setState({
            showFirstReport: true,
            checkedTheFirstReport: true,
          });
        } else {
          // no oldReport
          this.setState({
            showFirstReport: !this.state.showFirstReport,
            checkedTheFirstReport: false,
          });
        }
      }
      //    } //FIM hasCorrection
    };

    return (
      <div className={className}>
        <label>{label}</label>
        <Checkbox
          {...input}
          type="checkbox"
          value={value}
          defaultChecked={isChecked}
          onChange={this.handleChange}
        />
        {this.renderError(meta)}
      </div>
    );
  };

  renderCheckBoxTheFirstReport = ({ input, label, meta, value }) => {
    const className = `field ${meta.error && meta.touched ? "error" : ""}`;

    const isCheckedFR = (() => {
      if (this.props.initialValues) {
        if (this.props.initialValues.oldReport) {
          return true;
        } else {
          return false;
        }
      } else {
        return false;
      }
    })();

    this.handleChangeFR = () => {
      this.setState({
        checkedTheFirstReport: !this.state.checkedTheFirstReport,
      });
    };

    return (
      <div className={className}>
        <label>{label}</label>
        <Checkbox
          {...input}
          type="checkbox"
          value={value}
          defaultChecked={isCheckedFR}
          onChange={this.handleChangeFR}
        />
        {this.renderError(meta)}
      </div>
    );
  };

  // UnsatisfactoryReport

  renderOptionUnsatisfactoryReport() {
    // console.log(this.props);
    return this.state.oldReportLists.map((oldReportList) => ({
      value: oldReportList.id,
      label: oldReportList.title,
    }));
  }

  renderOptionUnsatisfactoryReportInitialValue() {
    if (this.props.initialValues) {
      const { oldReport } = this.props.initialValues;
      if (oldReport) {
        return {
          value: oldReport.id,
          label: oldReport.title,
        };
      }
    }
  }

  renderSelectUnsatisfactoryReport = ({
    input,
    value,
    label,
    meta,
    disabled,
  }) => {
    const className = `field ${meta.error && meta.touched ? "error" : ""}`;

    // console.log("DENTRO UNSAT", this.state.olderReportLists); XXXX

    const editValueUnsatisfactoryReport = (() => {
      if (this.props.initialValues) {
        if (input.value.id !== undefined) {
          input.onChange(this.props.initialValues.oldReport["@id"]);
          return (value) => input.onChange(`/api/reports/${value.value}`);
        } else {
          return (value) => input.onChange(`/api/reports/${value.value}`);
        }
      } else {
        return (value) => {
          input.onChange(`/api/reports/${value.value}`);
          // this.setState({
          //   // companyChosenForOrderServiceAndOldReport: value.value,
          //   orderServiceChosen: value.value,
          // });
        };
      }
    })();

    // REVER
    // if (value === undefined) {
    //   input.onChange("");
    // } else {
    //   return (value) => {
    //     input.onChange(`/api/reports/${value.value}`);
    //     // this.setState({ olderReportLists: [] });
    //   };
    // }

    return (
      <div className={className}>
        <label>{label}</label>
        <Select
          {...input}
          isDisabled={disabled}
          options={this.renderOptionUnsatisfactoryReport()}
          value={value}
          defaultValue={this.renderOptionUnsatisfactoryReportInitialValue()}
          placeholder={"Selecione o Insatisfatório..."}
          onChange={editValueUnsatisfactoryReport}
          onBlur={() => input.onBlur(input.value)}
          // isClearable={true}
        />
        {this.renderError(meta)}
      </div>
    );
  };

  // UnsatisfactoryReport

  renderCheckBoxIsArchived = ({ input, label, meta, value }) => {
    const className = `field ${meta.error && meta.touched ? "error" : ""}`;

    const isChecked = (() => {
      if (this.props.initialValues) {
        if (this.props.initialValues.isArchived === true) {
          return true;
        } else {
          return false;
        }
      } else {
        return false;
      }
    })();

    return (
      <div className={className}>
        <label>{label}</label>
        <Checkbox style={styles3}
          {...input}
          type="checkbox"
          value={value}
          defaultChecked={isChecked}
        />
        {this.renderError(meta)}
      </div>
    );
  };

  renderCheckBoxIsDeleted = ({ input, label, meta, value }) => {
    const className = `field ${meta.error && meta.touched ? "error" : ""}`;

    const isChecked = (() => {
     /* if (this.props.initialValues) {
        if (this.props.initialValues.isDeleted === true) {
          return true;
        } else {
          return false;
        }
      } else { */
        return false;
     // }
    })();

    this.handleChangeDel = () => {
      this.setState({
        checkedIsDelete: !this.state.checkedIsDelete,
      });
      if(!this.state.checkedIsDelete){

      }
    };

    return (
      <div className={className}>
        <label>{label}</label>
        <Checkbox style={styles3}
          {...input}
          type="checkbox"
          value={value}
          defaultChecked={isChecked}          
          onChange={this.handleChangeDel}
        />
        {this.renderError(meta)}
      </div>
    );
  };

  renderCheckBoxIsReleased = ({ input, label, meta, value }) => {
    const className = `field ${meta.error && meta.touched ? "error" : ""}`;

    const isChecked = (() => {
      if (this.props.initialValues) {
        if (this.props.initialValues.isReleased === true) {
          return true;
        } else {
          return false;
        }
      } else {
        return false;
      }
    })();

    return (
      <div className={className}>
        <label>{label}</label>
        <Checkbox  style={styles3}
          {...input}
          type="checkbox"
          value={value}
          defaultChecked={isChecked}
        />
        {this.renderError(meta)}
      </div>
    );
  };

  renderInputTextArea = ({ input, label, meta }) => {
    const className = `field ${meta.error && meta.touched ? "error" : ""}`;
    return (
      <div className={className}>
        <label>{label}</label>
        <textarea rows="4" {...input} autoComplete="off" />
        {this.renderError(meta)}
      </div>
    );
  };

  renderInputFile = ({ input, meta }) => {
    const className = `field ${meta.error && meta.touched ? "error" : ""}`;

    const { uploadedFiles } = this.state; 

    const urlValue = uploadedFiles.map((file) => file.url).toString();

    return (
      <div className={className}>
        <input
          {...input}
          value={this.props.change("file", urlValue)}
          hidden="on"
          autoComplete="off"
        />
        {this.renderError(meta)}
      </div>
    );
  };

  handleUpload = (files) => {
    // console.log(files);
    const uploadedFiles = files.map((file) => ({
      file,
      id: _.uniqueId(),
      name: file.name,
      readableSize: fileSize(file.size),
      preview: URL.createObjectURL(file),
      progress: 0,
      uploaded: false,
      error: false,
      url: null,
    }));

    // console.log(uploadedFiles);
    this.setState({
      uploadedFiles: this.state.uploadedFiles.concat(uploadedFiles),
    });

    uploadedFiles.forEach(this.processUpload);
  };

  updateFile = (id, data) => {
    // console.log("updateFile", data);
    this.setState({
      uploadedFiles: this.state.uploadedFiles.map((uploadedFile) => {
        return id === uploadedFile.id
          ? { ...uploadedFile, ...data }
          : uploadedFile;
      }),
    });
  };

  processUpload = (uploadedFile) => {
    const data = new FormData();

    data.append("file", uploadedFile.file, uploadedFile.name);

    agentApi
      .post("/api/upload", data, {
        onUploadProgress: (e) => {
          const progress = parseInt(Math.round((e.loaded * 100) / e.total));

          this.updateFile(uploadedFile.id, {
            progress,
          });
        },
      })
      .then((response) => {
        this.updateFile(uploadedFile.id, {
          uploaded: true,
          // id: response.data.id,
          url: response.data.file,
        });
        // console.log("URL", response.data.file);
      })

      .catch(() => {
        this.updateFile(uploadedFile.id, {
          error: true,
        });
      });
  };

  handleDelete = (id) => {
    this.setState({
      uploadedFiles: this.state.uploadedFiles.filter((file) => file.id !== id),
    });
  };

  // processStatusContent = () => {
  //   agentApi.get("/api/contracts/status", data);
  // };

  onSubmit = (formValues) => {
    formValues.userDid = "/api/users/"+this.props.currentUserId;
    if(!this.props.initialValues){
      formValues.userDidAction = "Criou";
    }else{
      if(formValues.isDeleted){
        formValues.userDidAction = "Deletou";
      }else if(formValues.isReleased){
        formValues.userDidAction = "Liberou";
      }else if(formValues.isArchived){
        formValues.userDidAction = "Arquivou";
      }else{
        formValues.userDidAction = "Editou";
      }      
    }
    //console.log(formValues);
    //return false;
    this.props.onSubmit(formValues);
  };

  render() {
    const { uploadedFiles } = this.state;
    const { pristine, submitting, initialValues } = this.props;

    const { currentUserIsAdmin } = this.props;

    const urlValue = uploadedFiles.map((file) => file.url).toString();

    const styles = {
      border: '5px solid rgba(255, 0, 0, 0.8)', 
      width:'300px',
      padding:'5px',  
      marginLeft: '4rem',    
    };

    const styles2 = {
      color: 'red', 
      marginTop: '-6px',
      padding:'5px',
      fontSize: "14px",
      fontWeight:'bold'
    };   

    const styles4 = {
      border: '5px solid rgba(0, 230, 67, 1)', 
      width:'300px',
      padding:'5px',
      marginLeft: '4rem',
    };
    
    const styles5 = {
      color: 'black', 
      marginTop: '-6px',
      padding:'5px',
      fontSize: "14px",
      fontWeight:'bold'
    };

    const styles6 = {
      border: '5px solid rgba(0, 217, 207, 1)', 
      width:'300px',
      padding:'5px',
      marginLeft: '4rem',
    };

    return (
      <form
        name="report"
        className="ui form error"
        onSubmit={this.props.handleSubmit(this.onSubmit)}
      >
        <Field
          type="text"
          name="company"
          component={this.renderSelectCompany}
          label="Nome Fantasia da Empresa"
          disabled={this.props.disable}
        />
        {this.state.showOrderService === true ? (
          <Field
            type="text"
            name="orderService"
            component={this.renderSelectOrderService}
            label="Ordem de Serviço"
          />
        ) : (
          ""
        )}
        <p style={{ color: "red", marginTop: "-6px", fontSize: "12px" }}>
          “Ordens de Serviços não arquivadas”.
        </p>
        <Field
          type="text"
          name="title"
          component={this.renderInput}
          label="Título do Laudo"
        />
        <div className="ui grid">
          <div className="eight wide column ">
            <div className="row">
              <Field
                type="date"
                name="startDate"
                label="Data Emissão"
                component={this.renderInputStartDate}
              />
            </div>
          </div>
          <div className="eight wide column ">
            <div className="row">
              <Field
                type="date"
                name="endDate"
                label="Válido Até"
                component={this.renderInputEndDate}
              />
            </div>
          </div>
        </div>
        <br />
        <div className={"ui equal width fields"}>
          <Field
            type="text"
            name="services"
            component={this.renderSelectService}
            label="Tipo de Serviço"
          />
          <Field
            type="text"
            name="equipments"
            component={this.renderSelectEquipment}
            label="Tipo de Equipamento"
          />
        </div>
        <Field
          type="text"
          name="observation"
          component={this.renderInputTextArea}
          label="Observações"
        />
        <div className={"ui equal width fields"}>
          <Field
            name="isNotSatisfactory"
            component={this.renderCheckBoxSatisfactory}
            label="Laudo esta insatisfatório?"
            autoComplete="off"
          />
          {this.state.showFirstReport === true ? (
            <Field
              name="isTheFirstReport"
              component={this.renderCheckBoxTheFirstReport}
              label="Existe um laudo anterior?"
              autoComplete="off"
            />
          ) : (
            ""
          )}
        </div>
        <div>
          {this.state.checkedTheFirstReport === true ? (
            <Field
              type="text"
              name="oldReport"
              component={this.renderSelectUnsatisfactoryReport}
              label="Laudo Anterior"
            />
          ) : (
            ""
          )}
        </div>
        <br />
        <Divider />
        <div className={"ui equal width fields"}>
          <div style={styles6}>
            <p style={styles5}>Marque se desejar ARQUIVAR o laudo.</p>
              <Field
                name="isArchived"
                component={this.renderCheckBoxIsArchived}
                label=""
                autoComplete="off"
              />
            </div>        
          {((this.props.initialValues && this.props.initialValues.isReleased === false && currentUserIsAdmin) || (!this.props.initialValues &&  currentUserIsAdmin)) ? (
            <div style={styles4}>
              <p style={styles5}>Marque se desejar LIBERAR o laudo.</p>
                <Field              
                  name="isReleased"
                  component={this.renderCheckBoxIsReleased}
                  label=""
                  autoComplete="off"
                />
            </div>
          ) : (
            ""
          )}
        
          <br /> 
          {this.props.initialValues && currentUserIsAdmin ? (
            <div style={styles}>
              <p style={styles2}>Marque se desejar DELETAR o laudo. (Esta ação não poderá ser desfeita!)</p>
                <Field
                  name="isDeleted"
                  component={this.renderCheckBoxIsDeleted}
                  label=""
                  autoComplete="off"
                />
                <div>
                {this.state.checkedIsDelete === true ? (
                  <Field
                    type="text"
                    name="observationDel"
                    component={this.renderInputTextArea}
                    label="Observações Deleção"
                  />
                  ) : (
                    ""
                  )}
                </div>
          </div>
          ) : (
            ""
          )} 
        </div>
        <br />      
        {!!uploadedFiles.length && (
          <DropboxFileList files={uploadedFiles} onDelete={this.handleDelete} />
        )}
        <br />

        {!!initialValues && (
          <div className="ui eight wide column">
            <div className="ui middle aligned">
              <PDFObject url={apiUrl + `/${initialValues.file}`} />
            </div>
          </div>
        )}
        <br />
        <Field
          name="file"
          component={this.renderInputFile}
          files={uploadedFiles}
        />
        {!!uploadedFiles.length || (
          <DropboxUpload onUpload={this.handleUpload} />
        )}
        <br />
        <div className={"ui container right aligned"}>
          <div className={"ui buttons"}>
            <Link className={"ui red button"} to={"/report/list"}>
              Cancelar
            </Link>
            <div className={"or"} data-text={"ou"} />
            {(!initialValues || uploadedFiles.length > 0) ? (
              <button
                className={"ui primary button"}
                disabled={urlValue.length === 0 || pristine || submitting}
              >
                Salvar
              </button>
            ) : (
              <button className={"ui primary button"} disabled={submitting}>
                Salvar
              </button>
            )}
          </div>
        </div>
      </form>
    );
  }
}

const validate = (formValues) => {
  const errors = {};

  if (!formValues.company) {
    errors.company = "Campo Obrigatório, escolha a empresa";
  }

  /*if (!formValues.orderService) {
    errors.orderService = "Campo Obrigatório, escolha a Ordem de Serviço";
  } */

  if (!formValues.title) {
    errors.title = "Campo Obrigatório, escolha o título";
  }

  if (!formValues.services) {
    errors.services = "Campo Obrigatório, Tipo de Serviço";
  }

  if (!formValues.equipments) {
    errors.equipments = "Campo Obrigatório, Tipo de Equipamento";
  }

  return errors;
};

const formWrapped = reduxForm({
  form: "reportForm",
  enableReinitialize: true,
  validate,
})(ReportForm);

const mapStateToProps = (state) => {
  return {
    companies: _.reject(
      // reject id which is the company owner
      _.orderBy(Object.values(state.companies), "fantasyName", "asc"),
      {
        type: { id: 1 },
      }
    ),
    services: _.orderBy(Object.values(state.service_types), "name", "asc"),
    equipments: _.orderBy(Object.values(state.equipment_types), "name", "asc"),
    orderServices: _.filter(
      _.orderBy(Object.values(state.orderServices), "name", "asc"),
      { isArchived: false }
    ),
    reports: _.orderBy(Object.values(state.reports), "id", "asc"),
    currentUserId: state.auth.userId,
    currentUserIsAdmin: state.auth.isAdmin,
    // olderReportList: Object.values(state.olderReportList),
  };
};

export default requireAuth(
  connect(mapStateToProps, {
    fetchCompanies,
    fetchServiceTypes,
    fetchEquipmentTypes,
    fetchOrderServices,
    fetchUsers,
    fetchReports,
  })(formWrapped)
);
