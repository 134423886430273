import _ from "lodash";
import React from "react";
import requireAuth from "../auth/requireAuth";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import ReactTable from "react-table-v6";
import { fetchContracts, selectedContract } from "../../actions";

import "react-table-v6/react-table.css";
import { Button, Grid, Radio } from "semantic-ui-react";

class ContractTableGrid extends React.Component {
  constructor(props) {
    super(props);
    this.state = { isToggle: false };
    this.handleToggle = this.handleToggle.bind(this);
  }

  componentDidMount() {
    if (this.props.contracts.length === 0) {
      this.props.fetchContracts();
    }
  }

  handleToggle(e) {
    e.preventDefault();

    this.setState({ isToggle: !this.state.isToggle });
  }

  render() {
    const { currentUserIsAdmin } = this.props;

    const ptBRFormatter = new Intl.DateTimeFormat("pt-BR", {
      day: "2-digit",
      month: "2-digit",
      year: "numeric",
    });

    const today = new Date();

    const { contracts } = this.props;

    const dataRender = this.state.isToggle
      ? _.filter(contracts, { isArchived: true })
      : _.filter(contracts, { isArchived: false });

    return (
      <>
        <div className={"ui grid"}>
          <div className="ui row">
            <div className={"sixteen wide column left bottom aligned content"}>
              <h1>Contratos</h1>
            </div>

            <div className="ui sixteen wide column right aligned">
              <div className="item">
                <div className="ui label mini green">
                  <i className="folder open icon" />
                  Ativo
                </div>
                &nbsp; &nbsp;
                <Radio
                  toggle
                  type="checkbox"
                  onChange={this.handleToggle}
                  style={{ marginBottom: "-0.5rem" }}
                />
                &nbsp; &nbsp;
                <div className="ui label mini blue">
                  <i className="folder outline icon" />
                  Arquivado
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className={"ui divider"} />
        {currentUserIsAdmin ? (
          <Link
            className="ui small icon primary right floated right labeled button"
            to={"/contract/new"}
          >
            <i aria-hidden="true" className="building icon" />
            Novo Contrato
          </Link>
        ) : null}
        <br />
        <br />
        <br />
        <ReactTable
          keyField="id"
          filterable
          showPagination={true}
          minRows={0}
          previousText="<"
          nextText=">"
          rowsText=""
          pageText="Página"
          ofText="de"
          noDataText="Nenhuma Informação"
          loadingText="Buscando dados..."
          defaultFilterMethod={(filter, row) =>
            String(row[filter.id])
              .toLowerCase()
              .indexOf(filter.value.toLowerCase()) > -1
          }
          defaultPageSize={10}
          className="-striped -highlight"
          data={dataRender}
          resolveData={(data) => data.map((row) => row)}
          columns={[
            {
              Header: "Empresa",
              accessor: "company.fantasyName",
            },
            {
              Header: "Título",
              accessor: "title",
            },
            {
              Header: "Início",
              id: "startDate",
              maxWidth: 100,
              accessor: (d) => {
                return ptBRFormatter.format(new Date(d.startDate));
              },
            },
            {
              Header: "Término",
              id: "endDate",
              maxWidth: 90,
              accessor: (d) => {
                return ptBRFormatter.format(new Date(d.endDate));
              },
              Cell: (props) => {
                const custom_date = props.value;
                return <span>{custom_date}</span>;
              },
              getProps: (state, rowInfo) => ({
                style: {
                  backgroundColor:
                    today > new Date(rowInfo.original.endDate)
                      ? "#fbd9d3"
                      : null,
                },
              }),
            },
            {
              Header: "Situação",
              accessor: "isArchived",
              filterable: false,
              maxWidth: 90,
              Cell: ({ value }) =>
                value === true ? (
                  <Grid>
                    <Grid.Column textAlign="center">
                      <Button
                        as="a"
                        color="blue"
                        size="mini"
                        icon="folder outline"
                        target="_blank"
                        rel="noreferrer"
                      />
                    </Grid.Column>
                  </Grid>
                ) : (
                  <Grid>
                    <Grid.Column textAlign="center">
                      <Button
                        as="a"
                        color="green"
                        size="mini"
                        icon="folder open"
                        target="_blank"
                        rel="noreferrer"
                      />
                    </Grid.Column>
                  </Grid>
                ),
            },
            {
              Header: "Ações",
              columns: [
                {
                  accessor: "id",
                  filterable: false,
                  maxWidth: 80,
                  Cell: (e) => (
                    <div className="ui column centered">
                      <Link
                        className="ui mini icon grey button"
                        to={`/contract/show/${e.value}`}
                      >
                        <i aria-hidden="true" className="file icon" />
                      </Link>
                      {currentUserIsAdmin ? (
                        <Link
                          className="ui mini icon yellow button"
                          to={`/contract/edit/${e.value}`}
                        >
                          <i aria-hidden="true" className="edit icon" />
                        </Link>
                      ) : null}
                    </div>
                  ),
                },
              ],
            },
          ]}
        />
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    currentUserIsAdmin: state.auth.isAdmin,
    contracts: _.orderBy(Object.values(state.contracts), "startDate", "desc"),
    selectedContract: state.selectedContract,
  };
};

export default requireAuth(
  connect(mapStateToProps, {
    fetchContracts,
    selectedContract,
  })(ContractTableGrid)
);
