import React from "react";
import requireAuth from "../auth/requireAuth";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import {
  createReport,
  fetchCompanies,
  fetchServiceTypes,
  fetchEquipmentTypes,
  fetchOrderServices,
} from "../../actions";
import ReportForm from "./ReportForm";

class ReportCreate extends React.Component {
  onSubmit = (formValues) => {
    this.props.createReport(formValues);
  };

  render() {
    return (
      <div className={"ui container"}>
        <div className={"ui grid"}>
          <div className={"ui row"}>
            <div
              className={
                "ui eight wide left aligned column bottom aligned content"
              }
            >
              <h2>Novo Laudo</h2>
            </div>
            <div
              className={
                "ui four wide right aligned column bottom aligned content"
              }
            >
              <h5>Identificação</h5>
            </div>
            <div
              className={
                "ui four wide right aligned column bottom aligned content"
              }
            >
              <Link
                className={"ui mini icon right labeled button"}
                to={"/report/list"}
              >
                <i aria-hidden="true" className="reply icon" /> Voltar
              </Link>
            </div>
          </div>
        </div>
        <div className={"ui divider"} />
        <ReportForm onSubmit={this.onSubmit} />
      </div>
    );
  }
}

export default requireAuth(
  connect(null, {
    createReport,
    fetchCompanies,
    fetchServiceTypes,
    fetchEquipmentTypes,
    fetchOrderServices,
  })(ReportCreate)
);
