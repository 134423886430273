import _ from "lodash";
import React from "react";
import requireAuth from "../auth/requireAuth";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import {
  editCompany,
  fetchCompany,
  fetchSegmentTypes,
  fetchContas,
} from "../../actions";
import CompanyForm from "./CompanyForm";

class CompanyEdit extends React.Component {
  componentDidMount() {
    this.props.fetchCompany(this.props.match.params.id);
  }

  onSubmit = (formValues) => {
    this.props.editCompany(this.props.match.params.id, formValues);
  };

  render() {
    if (!this.props.company) {
      return <div className="ui active centered inline loader" />;
    }

    return (
      <div className={"ui container"}>
        <div className={"ui grid"}>
          <div className={"ui row"}>
            <div
              className={
                "ui eight wide left aligned column bottom aligned content"
              }
            >
              <h2>Edição de Empresa</h2>
            </div>
            <div
              className={
                "ui four wide right aligned column bottom aligned content"
              }
            >
              <h5>Identificação</h5>
            </div>
            <div
              className={
                "ui four wide right aligned column bottom aligned content"
              }
            >
              <Link
                className={"ui mini icon right labeled button"}
                to={"/company/list"}
              >
                <i aria-hidden="true" className="reply icon" /> Voltar
              </Link>
            </div>
          </div>
        </div>
        <div className={"ui divider"} />
        <CompanyForm
          initialValues={_.pick(
            this.props.company,
            "type",
            "document",
            "segment.name",
            "segment[@id]",
            "segment.id",
            "conta.fantasyName",
            "conta[@id]",
            "conta.id",
            "socialReason",
            "fantasyName",
            "initials",
            "phone",
            "stateRegistration",
            "municipalRegistration",
            "observations",
            "address.id",
            "address.zip",
            "address.city",
            "address.street",
            "address.state",
            "address.number",
            "address.compliment",
            "address.neighbourhood"
          )}
          disable={true}
          onSubmit={this.onSubmit}
        />
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    company: state.companies[ownProps.match.params.id],
  };
};

export default requireAuth(
  connect(mapStateToProps, {
    fetchContas,
    editCompany,
    fetchCompany,
    fetchSegmentTypes,
  })(CompanyEdit)
);
