import React from "react";
import requireAuth from "../auth/requireAuth";

import OrderServiceTableGrid from "./OrderServicesTableGrid";

class OrderServiceList extends React.Component {
  render() {
    return (
      <div className="ui centered grid">
        <div className="ui fifteen wide column">
          <OrderServiceTableGrid />
        </div>
      </div>
    );
  }
}

export default requireAuth(OrderServiceList);
