import React from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import requireAuth from "../auth/requireAuth";
import { fetchContract } from "../../actions";

import { format } from "date-fns";
import { Button } from "semantic-ui-react";

// Call from env
const apiUrl = process.env.REACT_APP_UPLOAD_URL;

class ContractShow extends React.Component {
  componentDidMount() {
    this.props.fetchContract(this.props.match.params.id);
  }

  render() {
    const { contract } = this.props;

    if (!contract) {
      return <div className="ui active centered inline loader" />;
    }

    const startDateFormatted = new Date(contract.startDate);
    const endDateFormatted = new Date(contract.endDate);

    // let dateApproved = null;
    // if (contract.isApproved === true) {
    //   dateApproved = new Date(contract.movimentations[0].date);
    // }

    return (
      <div className={"ui container"}>
        <div className={"ui grid"}>
          <div className={"ui row"}>
            <div
              className={
                "ui eight wide left aligned column bottom aligned content"
              }
            >
              <h2>Visualização do Contrato</h2>
            </div>
            <div
              className={
                "ui four wide right aligned column bottom aligned content"
              }
            >
              <h5>Identificacão</h5>
            </div>
            <div
              className={
                "ui four wide right aligned column bottom aligned content"
              }
            >
              <Link
                className={"ui mini icon right labeled button"}
                to={"/contract/list"}
              >
                <i aria-hidden="true" className="reply icon" /> Voltar
              </Link>
            </div>
          </div>
        </div>
        <div className="ui divider" />

        <div className="ui header">
          <h3>
            <i aria-hidden="true" className="settings icon" />
            <span> Nome Fantasia:&nbsp; {contract.company.fantasyName}</span>
          </h3>
        </div>
        <div className="ui vertically grid">
          <div className="sixteen wide column">
            <div className="ui meta">
              <h5>Título:</h5>
            </div>
            <br />
            <div className="ui content">
              <h4>{contract.title}</h4>
            </div>
            <div className="ui divider" />
          </div>
          <div className="two column row">
            <div className="column">
              <div className="ui meta">
                <h5>Data de Assinatura:</h5>
              </div>
              <br />
              <div className="ui content">
                <h4>{format(startDateFormatted, "dd-MM-yyyy")}</h4>
              </div>
              <div className="ui divider" />
            </div>
            <div className="column">
              <div className="ui meta">
                <h5>Data Fim:</h5>
              </div>
              <br />
              <div className="ui content">
                <h4>{format(endDateFormatted, "dd-MM-yyyy")}</h4>
              </div>
              <div className="ui divider" />
            </div>
          </div>
          <div className="sixteen wide column">
            <div className="ui meta">
              <h5>Observações:</h5>
            </div>
            <br />
            <div className="ui content">
              <h4>{contract.observation}</h4>
            </div>
            <div className="ui divider" />
          </div>
          <div className="sixteen wide column">
            <div className="ui meta">
              <h5>Ações para Contrato:</h5>
            </div>
            <br />
            <div className="ui grid">
              <div className="ui four wide column">
                <Button
                  as="a"
                  label="Ver e Imprimir"
                  color="yellow"
                  size="tiny"
                  icon="print"
                  href={apiUrl + `/${contract.file}`}
                  target="_blank"
                  rel="noreferrer"
                />
              </div>
              {/*{contract.isApproved === true ? (*/}
              {/*  <div className="ui twelve wide column right aligned centered">*/}
              {/*    <div className="ui green mini message">*/}
              {/*      <div className="ui four wide column middle aligned">*/}
              {/*        <h5>*/}
              {/*          Assinado por: {contract.movimentations[0].user.name}*/}
              {/*        </h5>*/}
              {/*      </div>*/}
              {/*      <div className="ui four wide column middle aligned">*/}
              {/*        <h5>*/}
              {/*          {format(*/}
              {/*            dateApproved,*/}
              {/*            "'Dia' dd-MM-yyyy 'às' HH:mm 'horas'"*/}
              {/*          )}*/}
              {/*        </h5>*/}
              {/*      </div>*/}
              {/*    </div>*/}
              {/*  </div>*/}
              {/*) : (*/}
              {/*  <>*/}
              {/*    <div className="ui twelve wide column right aligned bottom aligned">*/}
              {/*      <div className="ui red mini message">*/}
              {/*        <h5>Ainda não assinado!</h5>*/}
              {/*      </div>*/}
              {/*    </div>*/}
              {/*  </>*/}
              {/*)}*/}
            </div>

            <div className="ui divider" />
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    contract: state.contracts[ownProps.match.params.id],
  };
};

export default requireAuth(
  connect(mapStateToProps, {
    fetchContract,
  })(ContractShow)
);
