import {
  FILE_UPLOAD_REQUEST,
  FILE_UPLOADED,
  FILE_UPLOAD_ERROR,
} from "../actions/types";

// eslint-disable-next-line import/no-anonymous-default-export
export default (
  state = {
    isSending: false,
    file: null,
    files: [],
  },
  action
) => {
  switch (action.type) {
    case FILE_UPLOAD_REQUEST:
      return {
        ...state,
        isSending: true,
      };
    case FILE_UPLOADED:
      return {
        ...state,
        isSending: false,
        file: action.file,
        files: state.files.concat(action.file),
      };
    case FILE_UPLOAD_ERROR:
      return {
        ...state,
        isSending: false,
      };

    default:
      return state;
  }
};
