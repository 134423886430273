import _ from "lodash";
import React from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import requireAuth from "../auth/requireAuth";
import ReactTable from "react-table-v6";
import { fetchCompanies, selectedCompany } from "../../actions";

import "react-table-v6/react-table.css";

class CompanyTableGrid extends React.Component {
  componentDidMount() {
    if (this.props.companies.length === 0) {
      this.props.fetchCompanies();
    }
  }

  render() {
    const { currentUserIsAdmin } = this.props;

    const onRowClick = (state, rowInfo) => {
      return {
        onClick: (e) => {
          this.props.selectedCompany(rowInfo.original);
        },
      };
    };

    return (
      <>
        <div className="twelve wide column left aligned">
          <h1>Empresas</h1>
        </div>
        <div className="ui divider" />
        {currentUserIsAdmin ? (
          <Link
            className="ui small icon primary right floated right labeled button"
            to={"/company/new"}
          >
            <i aria-hidden="true" className="building icon" />
            Nova Empresa
          </Link>
        ) : null}
        <br />
        <br />
        <br />
        <ReactTable
          keyField="id"
          getTdProps={onRowClick}
          filterable
          showPagination={true}
          minRows={0}
          previousText="<"
          nextText=">"
          rowsText=""
          pageText="Página"
          ofText="de"
          noDataText="Nenhuma Informação"
          loadingText="Buscando dados..."
          defaultFilterMethod={(filter, row) =>
            String(row[filter.id])
              .toLowerCase()
              .indexOf(filter.value.toLowerCase()) > -1
          }
          defaultPageSize={10}
          className="-striped -highlight"
          data={this.props.companies}
          resolveData={(data) => data.map((row) => row)}
          columns={[
            {
              Header: "Grupo Empresarial",
              accessor: "conta.fantasyName",
            },
            {
              Header: "Empresa Nome Fantasia",
              accessor: "fantasyName",
            },
            {
              Header: "Sigla",
              accessor: "initials",
            },
            {
              Header: "CNPJ",
              accessor: "document",
            },
            {
              Header: "Segmento",
              accessor: "segment.name",
            },
            {
              Header: "Cidade",
              accessor: "address.city",
            },
            {
              Header: "Ações",
              columns: [
                {
                  accessor: "id",
                  filterable: false,
                  maxWidth: 80,
                  Cell: (e) => (
                    <div className="ui column centered">
                      <Link
                        className="ui mini icon grey button"
                        to={`/company/show/${e.value}`}
                      >
                        <i aria-hidden="true" className="file icon" />
                      </Link>
                      {currentUserIsAdmin ? (
                        <Link
                          className="ui mini icon yellow button"
                          to={`/company/edit/${e.value}`}
                        >
                          <i aria-hidden="true" className="edit icon" />
                        </Link>
                      ) : null}
                    </div>
                  ),
                },
              ],
            },
          ]}
        />
      </>
    );
  }
}

const mapStateToProps = (state) => {
  // console.log(state.companies);
  return {
    currentUserIsAdmin: state.auth.isAdmin,
    companies: _.reject(
      // reject id which is the company owner
      _.orderBy(Object.values(state.companies), "id", "desc"),
      {
        type: { id: 1 },
      }
    ),
    selectedCompany: state.selectedCompany,
  };
};

export default requireAuth(
  connect(mapStateToProps, {
    fetchCompanies,
    selectedCompany,
  })(CompanyTableGrid)
);
