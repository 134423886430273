import React from "react";
import requireAuth from "../auth/requireAuth";

import CompanyUserTableGrid from "./CompanyUserTableGrid";

class CompanyUserList extends React.Component {
  render() {
    return (
      <div className="ui centered grid">
        <div className="ui fifteen wide column">
          <CompanyUserTableGrid />
        </div>
      </div>
    );
  }
}

export default requireAuth(CompanyUserList);
