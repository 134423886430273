import _ from "lodash";
import React from "react";
import requireAuth from "../auth/requireAuth";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { Field, reduxForm } from "redux-form";
import Select from "react-select";
import fileSize from "filesize";
import { Checkbox } from "semantic-ui-react";
import DropboxUpload from "../../util/dropzone/DropboxUpload";
import DropboxFileList from "../../util/dropzone/DropboxFileList";

import { PDFObject } from "react-pdfobject";

import agentApi from "../../apis/agentApi";

import DayPickerInput from "react-day-picker/DayPickerInput";
import "react-day-picker/lib/style.css";

import MomentLocaleUtils, {
  formatDate,
  parseDate,
} from "react-day-picker/moment";

import "moment/locale/pt-br";

import {
  fetchCompanies,
  fetchServiceTypes,
  fetchEquipmentTypes,
} from "../../actions";

// Call from env
const apiUrl = process.env.REACT_APP_UPLOAD_URL;

class OrderServiceForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      value: [],
      label: [],
      selectedDay: undefined,
      file: null,
      uploadedFiles: [],
    };
  }

  componentDidMount() {
    this.props.fetchCompanies();
    this.props.fetchServiceTypes();
    this.props.fetchEquipmentTypes();
  }

  renderError({ error, touched }) {
    if (touched && error) {
      return (
        <div className={"ui tiny error message"}>
          <div className={"header"}>{error}</div>
        </div>
      );
    }
  }

  renderInput = ({ input, label, meta }) => {
    const className = `field ${meta.error && meta.touched ? "error" : ""}`;
    return (
      <div className={className}>
        <label>{label}</label>
        <input {...input} autoComplete="off" />
        {this.renderError(meta)}
      </div>
    );
  };

  renderOptionCompany() {
    const { companies } = this.props;
    return companies.map((company) => ({
      value: company.id,
      label: company.fantasyName,
    }));
  }

  renderOptionCompanyInitialValue() {
    const { initialValues } = this.props;
    if (initialValues) {
      const { company } = initialValues;
      return {
        value: company.id,
        label: company.fantasyName,
      };
    }
  }

  renderSelectCompany = ({ input, value, label, meta }) => {
    const className = `field ${meta.error && meta.touched ? "error" : ""}`;

    const editValueCompany = (() => {
      const { initialValues } = this.props;
      if (initialValues) {
        if (input.value.id !== undefined) {
          input.onChange(initialValues.company["@id"]);
          return (value) => input.onChange(`/api/companies/${value.value}`);
        } else {
          return (value) => input.onChange(`/api/companies/${value.value}`);
        }
      } else {
        return (value) => input.onChange(`/api/companies/${value.value}`);
      }
    })();

    return (
      <div className={className}>
        <label>{label}</label>
        <Select
          {...input}
          options={this.renderOptionCompany()}
          value={value}
          defaultValue={this.renderOptionCompanyInitialValue()}
          placeholder={"Selecione a empresa..."}
          onChange={editValueCompany}
          onBlur={() => input.onBlur(input.value)}
        />
        {this.renderError(meta)}
      </div>
    );
  };

  renderOptionService() {
    const { services } = this.props;
    return services.map((service) => ({
      value: service.value,
      label: service.name,
    }));
  }

  renderOptionServiceInitialValue() {
    if (this.props.initialValues) {

      var newArray = [];
      var isObject = 0;

      if(!_.isArray(this.props.initialValues.services)){
        isObject = 1;
        var j = 0;
        for (var i in this.props.initialValues.services){
          if(this.props.initialValues.services[i] != ''){
            newArray[j] = this.props.initialValues.services[i];
            j++;
          }
        }
      }

      if(isObject){
        this.props.initialValues.services = newArray;
      }

      const { services } = this.props.initialValues;
      return services.map((service) => ({
        value: service.value,
        label: service.name,
      }));
    }
  }

  renderSelectService = ({ input, value, label, meta }) => {
    const className = `field ${meta.error && meta.touched ? "error" : ""}`;

    const editValueService = (() => {
      if (this.props.initialValues) {        
        if (_.isObject(input.value[0])) {
          const { services } = this.props.initialValues;
          input.onChange(
            services.map((service) => {
              return service["@id"];
            })
          );
          return (value) => input.onChange([`/api/services/${value.value}`]);
        } else {
          return (value) => input.onChange([`/api/services/${value.value}`]);
        }
      } else {
        return (value) => input.onChange([`/api/services/${value.value}`]);
      }
    })();

    return (
      <div className={className}>
        <label>{label}</label>
        <Select
          {...input}
          options={this.renderOptionService()}
          value={value}
          defaultValue={this.renderOptionServiceInitialValue()}
          placeholder={"Selecione o serviço..."}
          onChange={editValueService}
          onBlur={() => input.onBlur(input.value)}
        />
        {this.renderError(meta)}
      </div>
    );
  };

  renderOptionEquipment() {
    const { equipments } = this.props;
    return equipments.map((equipment) => ({
      value: equipment.value,
      label: equipment.name,
    }));
  }

  renderOptionEquipmentInitialValue() {
    if (this.props.initialValues) {

      var newArray = [];
      var isObject = 0;

      if(!_.isArray(this.props.initialValues.equipments)){
        isObject = 1;
        var j = 0;
        for (var i in this.props.initialValues.equipments){
          if(this.props.initialValues.equipments[i] != ''){
            newArray[j] = this.props.initialValues.equipments[i];
            j++;
          }
        }
      }

      if(isObject){
        this.props.initialValues.equipments = newArray;
      }

      const { equipments } = this.props.initialValues;
      return equipments.map((equipment) => ({
        value: equipment.value,
        label: equipment.name,
      }));
    }
  }

  renderSelectEquipment = ({ input, value, label, meta }) => {
    const className = `field ${meta.error && meta.touched ? "error" : ""}`;

    const editValueEquipment = (() => {
      if (this.props.initialValues) {
        if (_.isObject(input.value[0])) {
          const { equipments } = this.props.initialValues;
          input.onChange(
            equipments.map((equipment) => {
              return equipment["@id"];
            })
          );
          return (value) => input.onChange([`/api/equipment/${value.value}`]);
        } else {
          return (value) => input.onChange([`/api/equipment/${value.value}`]);
        }
      } else {
        return (value) => input.onChange([`/api/equipment/${value.value}`]);
      }
    })();

    return (
      <div className={className}>
        <label>{label}</label>
        <Select
          {...input}
          options={this.renderOptionEquipment()}
          value={value}
          defaultValue={this.renderOptionEquipmentInitialValue()}
          placeholder={"Selecione o equipamento..."}
          onChange={editValueEquipment}
          onBlur={() => input.onBlur(input.value)}
        />
        {this.renderError(meta)}
      </div>
    );
  };

  renderInputStartDate = ({ input, label, meta, type }) => {
    const className = `field ${meta.error && meta.touched ? "error" : ""}`;

    const formatValue = (() => {
      const { initialValues } = this.props;
      if (initialValues) {
        const { startDate } = initialValues;
        return formatDate(startDate, "L", "pt-br");
      }
    })();

    return (
      <div className={className}>
        <label>{label}</label>
        <DayPickerInput
          {...input}
          type={type}
          onDayChange={input.onChange}
          selectedDay={this.state.selectedDay}
          formatDate={formatDate}
          parseDate={parseDate}
          value={formatValue}
          format="L"
          placeholder={`${formatDate(new Date(), "L", "pt-br")}`}
          dayPickerProps={{
            locale: "pt-br",
            localeUtils: MomentLocaleUtils,
          }}
        />
        {this.renderError(meta)}
      </div>
    );
  };

  // renderInputEndDate = ({ input, label, meta, type }) => {
  //   const className = `field ${meta.error && meta.touched ? "error" : ""}`;
  //
  //   const formatValue = (() => {
  //     const { initialValues } = this.props;
  //     if (initialValues) {
  //       const { endDate } = initialValues;
  //       return formatDate(endDate, "L", "pt-br");
  //     }
  //   })();
  //
  //   return (
  //     <div className={className}>
  //       <label>{label}</label>
  //       <DayPickerInput
  //         {...input}
  //         type={type}
  //         onDayChange={input.onChange}
  //         selectedDay={this.state.selectedDay}
  //         formatDate={formatDate}
  //         parseDate={parseDate}
  //         value={formatValue}
  //         format="L"
  //         placeholder={`${formatDate(new Date(), "L", "pt-br")}`}
  //         dayPickerProps={{
  //           locale: "pt-br",
  //           localeUtils: MomentLocaleUtils,
  //         }}
  //       />
  //       {this.renderError(meta)}
  //     </div>
  //   );
  // };

  renderCheckBox = ({ input, label, meta, value }) => {
    const className = `field ${meta.error && meta.touched ? "error" : ""}`;

    const isChecked = (() => {
      const { initialValues } = this.props;
      if (initialValues) {
        return initialValues.isArchived === true;
      }
    })();

    return (
      <div className={className}>
        <label>{label}</label>
        <Checkbox
          {...input}
          type="checkbox"
          value={value}
          defaultChecked={isChecked}
        />
        {this.renderError(meta)}
      </div>
    );
  };

  renderInputTextArea = ({ input, label, meta }) => {
    const className = `field ${meta.error && meta.touched ? "error" : ""}`;
    return (
      <div className={className}>
        <label>{label}</label>
        <textarea rows="4" {...input} autoComplete="off" />
        {this.renderError(meta)}
      </div>
    );
  };

  renderInputFile = ({ input, meta }) => {
    const className = `field ${meta.error && meta.touched ? "error" : ""}`;

    const { uploadedFiles } = this.state;

    const urlValue = uploadedFiles.map((file) => file.url).toString();

    return (
      <div className={className}>
        <input
          {...input}
          value={this.props.change("file", urlValue)}
          hidden="on"
          autoComplete="off"
        />
        {this.renderError(meta)}
      </div>
    );
  };

  handleUpload = (files) => {
    const uploadedFiles = files.map((file) => ({
      file,
      id: _.uniqueId(),
      name: file.name,
      readableSize: fileSize(file.size),
      preview: URL.createObjectURL(file),
      progress: 0,
      uploaded: false,
      error: false,
      url: null,
    }));

    // console.log(uploadedFiles);
    this.setState({
      uploadedFiles: this.state.uploadedFiles.concat(uploadedFiles),
    });

    uploadedFiles.forEach(this.processUpload);
  };

  updateFile = (id, data) => {
    this.setState({
      uploadedFiles: this.state.uploadedFiles.map((uploadedFile) => {
        return id === uploadedFile.id
          ? { ...uploadedFile, ...data }
          : uploadedFile;
      }),
    });
  };

  processUpload = (uploadedFile) => {
    const data = new FormData();

    data.append("file", uploadedFile.file, uploadedFile.name);

    agentApi
      .post("/api/upload", data, {
        onUploadProgress: (e) => {
          const progress = parseInt(Math.round((e.loaded * 100) / e.total));

          this.updateFile(uploadedFile.id, {
            progress,
          });
        },
      })
      .then((response) => {
        this.updateFile(uploadedFile.id, {
          uploaded: true,
          url: response.data.file,
        });
      })

      .catch(() => {
        this.updateFile(uploadedFile.id, {
          error: true,
        });
      });
  };

  handleDelete = (id) => {
    this.setState({
      uploadedFiles: this.state.uploadedFiles.filter((file) => file.id !== id),
    });
  };

  // processStatusContent = () => {
  //   agentApi.get("/api/contracts/status", data);
  // };

  onSubmit = (formValues) => {
    this.props.onSubmit(formValues);
  };

  render() {
    const { uploadedFiles } = this.state;
    const { pristine, submitting, initialValues } = this.props;

    const urlValue = uploadedFiles.map((file) => file.url).toString();

    return (
      <form
        name="orderServiceForm"
        className="ui form error"
        onSubmit={this.props.handleSubmit(this.onSubmit)}
      >
        <Field
          type="text"
          name="company"
          component={this.renderSelectCompany}
          label="Nome Fantasia da Empresa"
        />
        <Field
          type="text"
          name="title"
          component={this.renderInput}
          label="Título da Ordem de Serviço"
        />
        <div className="ui grid">
          <div className="eight wide column ">
            <div className="row">
              <Field
                type="date"
                name="startDate"
                label="Data Emissão"
                component={this.renderInputStartDate}
              />
            </div>
          </div>
          {/*<div className="eight wide column ">*/}
          {/*  <div className="row">*/}
          {/*    <Field*/}
          {/*      type="date"*/}
          {/*      name="endDate"*/}
          {/*      label="Válido Até"*/}
          {/*      component={this.renderInputEndDate}*/}
          {/*    />*/}
          {/*  </div>*/}
          {/*</div>*/}
        </div>
        <br />
        <div className={"ui equal width fields"}>
          <Field
            type="text"
            name="services"
            component={this.renderSelectService}
            label="Tipo de Serviço"
          />
          <Field
            type="text"
            name="equipments"
            component={this.renderSelectEquipment}
            label="Tipo de Equipamento"
          />
        </div>
        <Field
          type="text"
          name="observation"
          component={this.renderInputTextArea}
          label="Observações"
        />
        <Field
          name="isArchived"
          component={this.renderCheckBox}
          label="Marque se desejar arquivar a ordem de serviço."
          autoComplete="off"
        />
        {!!uploadedFiles.length && (
          <DropboxFileList files={uploadedFiles} onDelete={this.handleDelete} />
        )}
        <br />
        <br />
        {!!initialValues && (
          <div className="ui eight wide column">
            <div className="ui middle aligned">
              <PDFObject url={apiUrl + `/${initialValues.file}`} />
            </div>
          </div>
        )}
        <br />
        <Field
          name="file"
          component={this.renderInputFile}
          files={uploadedFiles}
        />
        {!!uploadedFiles.length || (
          <DropboxUpload onUpload={this.handleUpload} />
        )}
        <br />
        <div className={"ui container right aligned"}>
          <div className={"ui buttons"}>
            <Link className={"ui red button"} to={"/order_service/list"}>
              Cancelar
            </Link>
            <div className={"or"} data-text={"ou"} />
            {!initialValues ? (
              <button
                className={"ui primary button"}
                disabled={urlValue.length === 0 || pristine || submitting}
              >
                Salvar
              </button>
            ) : (
              <button className={"ui primary button"} disabled={submitting}>
                Salvar
              </button>
            )}
          </div>
        </div>
      </form>
    );
  }
}

const validate = (formValues) => {
  const errors = {};

  if (!formValues.company) {
    errors.company = "Campo Obrigatório, escolha a empresa";
  }

  if (!formValues.title) {
    errors.title = "Campo Obrigatório, escolha o título";
  }

  if (!formValues.services) {
    errors.services = "Campo Obrigatório, Tipo de Serviço";
  }

  if (!formValues.equipments) {
    errors.equipments = "Campo Obrigatório, Tipo de Equipamento";
  }

  return errors;
};

const formWrapped = reduxForm({
  form: "orderServiceForm",
  enableReinitialize: true,
  validate,
})(OrderServiceForm);

const mapStateToProps = (state) => {
  return {
    companies: _.reject(
      // reject id which is the company owner
      _.orderBy(Object.values(state.companies), "fantasyName", "asc"),
      {
        type: { id: 1 },
      }
    ),
    services: _.orderBy(Object.values(state.service_types), "name", "asc"),
    equipments: _.orderBy(Object.values(state.equipment_types), "name", "asc"),
  };
};

export default requireAuth(
  connect(mapStateToProps, {
    fetchCompanies,
    fetchServiceTypes,
    fetchEquipmentTypes,
  })(formWrapped)
);
