import React from "react";
import requireAuth from "../auth/requireAuth";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { createCompanyUser, fetchCompanies, fetchContas } from "../../actions";

import CompanyUserForm from "./CompanyUserForm";

class CompanyUserCreate extends React.Component {
  onSubmit = (formValues) => {
    this.props.createCompanyUser(formValues);
  };

  render() {
    // console.log(this.props);
    return (
      <div className={"ui container"}>
        <div className={"ui grid"}>
          <div className={"ui row"}>
            <div
              className={
                "ui eight wide left aligned column bottom aligned content"
              }
            >
              <h2>G7 Serviços de Proteção Radiológico - Novo Usuário</h2>
            </div>
            <div
              className={
                "ui four wide right aligned column bottom aligned content"
              }
            >
              <h5>Identificação</h5>
            </div>
            <div
              className={
                "ui four wide right aligned column bottom aligned content"
              }
            >
              <Link
                className={"ui mini icon right labeled button"}
                to={"/company_user/list"}
              >
                <i aria-hidden="true" className="reply icon" /> Voltar
              </Link>
            </div>
          </div>
        </div>
        <div className={"ui divider"} />
        <CompanyUserForm onSubmit={this.onSubmit} />
      </div>
    );
  }
}

export default requireAuth(
  connect(null, {
    createCompanyUser,
    fetchCompanies,
    fetchContas,
  })(CompanyUserCreate)
);
