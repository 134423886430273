import _ from "lodash";
import {
  SELECTED_CONTA,
  CREATE_CONTA,
  FETCH_CONTAS,
  FETCH_CONTA,
  EDIT_CONTA,
  DELETE_CONTA,
} from "../actions/types";

// eslint-disable-next-line import/no-anonymous-default-export
export default (state = {}, action) => {
  switch (action.type) {
    case SELECTED_CONTA:
      return { ...state, [action.payload.id]: action.payload };
    case FETCH_CONTAS:
      return { ...state, ..._.mapKeys(action.payload["hydra:member"], "id") };
    case FETCH_CONTA:
      return { ...state, [action.payload.id]: action.payload };
    case CREATE_CONTA:
      return { ...state, [action.payload.id]: action.payload };
    case EDIT_CONTA:
      return { ...state, [action.payload.id]: action.payload };
    case DELETE_CONTA:
      return _.omit(state, action.payload);

    default:
      return state;
  }
};
