import React from "react";
import requireAuth from "../auth/requireAuth";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { createContract, fetchCompanies } from "../../actions";
import ContractForm from "./ContractForm";

class ContractCreate extends React.Component {
  onSubmit = (formValues) => {
    this.props.createContract(formValues);
  };

  render() {
    return (
      <div className="ui container">
        <div className="ui grid">
          <div className="ui row">
            <div className="ui eight wide left aligned column bottom aligned content">
              <h2>Novo Contrato</h2>
            </div>
            <div className="ui four wide right aligned column bottom aligned content">
              <h5>Identificação</h5>
            </div>
            <div className="ui four wide right aligned column bottom aligned content">
              <Link
                className="ui mini icon right labeled button"
                to={"/contract/list"}
              >
                <i aria-hidden="true" className="reply icon" /> Voltar
              </Link>
            </div>
          </div>
        </div>
        <div className={"ui divider"} />
        <ContractForm onSubmit={this.onSubmit} />
      </div>
    );
  }
}

export default requireAuth(
  connect(null, {
    createContract,
    fetchCompanies,
  })(ContractCreate)
);
