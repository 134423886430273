import React from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import requireAuth from "../auth/requireAuth";
import { fetchOrderService } from "../../actions";

import { format } from "date-fns";
import { Button } from "semantic-ui-react";

// Call from env
const apiUrl = process.env.REACT_APP_UPLOAD_URL;

class OrderServiceShow extends React.Component {
  componentDidMount() {
    this.props.fetchOrderService(this.props.match.params.id);
  }

  render() {
    const { orderService } = this.props;

    if (!orderService) {
      return <div className="ui active centered inline loader" />;
    }

    const startDateFormatted = new Date(orderService.startDate);
    const endDateFormatted = new Date(orderService.endDate);

    let dateApproved = null;
    if (orderService.isApproved === true) {
      dateApproved = new Date(orderService.movimentations[0].date);
    }

    const serviceToArray = Array.from(this.props.orderService.services).map(
      (newServ) => {
        return (
          <div className="ui list">
            <div className="item">{newServ.name}</div>
          </div>
        );
      }
    );

    const equipmentToArray = Array.from(this.props.orderService.equipments).map(
      (newEquip) => {
        return (
          <div className="ui list">
            <div className="item">{newEquip.name}</div>
          </div>
        );
      }
    );

    return (
      <div className={"ui container"}>
        <div className={"ui grid"}>
          <div className={"ui row"}>
            <div
              className={
                "ui eight wide left aligned column bottom aligned content"
              }
            >
              <h2>Visualização da Ordem de Serviço</h2>
            </div>
            <div
              className={
                "ui four wide right aligned column bottom aligned content"
              }
            >
              <h5>Identificacão</h5>
            </div>
            <div
              className={
                "ui four wide right aligned column bottom aligned content"
              }
            >
              <Link
                className={"ui mini icon right labeled button"}
                to={"/order_service/list"}
              >
                <i aria-hidden="true" className="reply icon" /> Voltar
              </Link>
            </div>
          </div>
        </div>
        <div className="ui divider" />

        <div className="ui header">
          <h3>
            <i aria-hidden="true" className="settings icon" />
            <span>
              {" "}
              Nome Fantasia:&nbsp; {orderService.company.fantasyName}
            </span>
          </h3>
        </div>
        <div className="ui vertically grid">
          <div className="sixteen wide column">
            <div className="ui meta">
              <h5>Título:</h5>
            </div>
            <br />
            <div className="ui content">
              <h4>{orderService.title}</h4>
            </div>
            <div className="ui divider" />
          </div>
          <div className="two column row">
            <div className="column">
              <div className="ui meta">
                <h5>Data da Emissão:</h5>
              </div>
              <br />
              <div className="ui content">
                <h4>{format(startDateFormatted, "dd-MM-yyyy")}</h4>
              </div>
              <div className="ui divider" />
            </div>
            {/*<div className="column">*/}
            {/*  <div className="ui meta">*/}
            {/*    <h5>Data Fim:</h5>*/}
            {/*  </div>*/}
            {/*  <br />*/}
            {/*  <div className="ui content">*/}
            {/*    <h4>{format(endDateFormatted, "dd-MM-yyyy")}</h4>*/}
            {/*  </div>*/}
            {/*  <div className="ui divider" />*/}
            {/*</div>*/}
          </div>
          <div className="two column row">
            <div className="column">
              <div className="ui meta">
                <h5>Serviços:</h5>
              </div>
              <h5>{serviceToArray}</h5>
              <div className="ui divider" />
            </div>
            <div className="column">
              <div className="ui meta">
                <h5>Equipamentos:</h5>
              </div>
              <h5>{equipmentToArray}</h5>
              <div className="ui divider" />
            </div>
          </div>
          <div className="sixteen wide column">
            <div className="ui meta">
              <h5>Observações:</h5>
            </div>
            <br />
            <div className="ui content">
              <h4>{orderService.observation}</h4>
            </div>
            <div className="ui divider" />
          </div>
          <div className="sixteen wide column">
            <div className="ui meta">
              <h5>Ações para Ordem de Serviço:</h5>
            </div>
            <br />

            <div className="ui grid">
              <div className="ui four wide column">
                <Button
                  as="a"
                  label="Ver e Imprimir"
                  color="yellow"
                  size="tiny"
                  icon="print"
                  href={apiUrl + `/${orderService.file}`}
                  target="_blank"
                  rel="noreferrer"
                />
              </div>
              {/*{orderService.isApproved === true ? (*/}
              {/*  <div className="ui twelve wide column right aligned centered">*/}
              {/*    <div className="ui green mini message">*/}
              {/*      <div className="ui four wide column middle aligned">*/}
              {/*        <h5>*/}
              {/*          Assinado por: {orderService.movimentations[0].user.name}*/}
              {/*        </h5>*/}
              {/*      </div>*/}
              {/*      <div className="ui four wide column middle aligned">*/}
              {/*        <h5>*/}
              {/*          {format(*/}
              {/*            dateApproved,*/}
              {/*            "'Dia' dd-MM-yyyy 'às' HH:mm 'horas'"*/}
              {/*          )}*/}
              {/*        </h5>*/}
              {/*      </div>*/}
              {/*    </div>*/}
              {/*  </div>*/}
              {/*) : (*/}
              {/*  <>*/}
              {/*    <div className="ui twelve wide column right aligned bottom aligned">*/}
              {/*      <div className="ui red mini message">*/}
              {/*        <h5>Ainda não assinado!</h5>*/}
              {/*      </div>*/}
              {/*    </div>*/}
              {/*  </>*/}
              {/*)}*/}
            </div>

            <div className="ui divider" />
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    orderService: state.orderServices[ownProps.match.params.id],
  };
};

export default requireAuth(
  connect(mapStateToProps, { fetchOrderService })(OrderServiceShow)
);
